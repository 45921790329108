<div class="page-background-block"></div>
<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Download Center</div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    </div>
  </div>
<div class="page-content" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="16px">
    <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="card-header"></div>
          <mat-card-title>eID-X Chrome Extensie</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button (click)="openChromeExtension()">Downloaden van Chrome Webstore</button>
        </mat-card-actions>
    </mat-card>
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="card-header"></div>
          <mat-card-title>eID-X Reader</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
          <button mat-button (click)="downloadEid('mac', 'x86', 'eID-X Installer.pkg')">Mac (x86)</button>
          <button mat-button (click)="downloadEid('mac', 'arm', 'eID-X Installer.pkg')">Mac (ARM)</button>
          <button mat-button (click)="downloadEid('windows', 'x86', 'eID-X Installer.exe')">Windows</button>
        </mat-card-actions>
      </mat-card>
    </div>