<div fxFill fxLayout="row" fxLayoutAlign="space-between">
  <div fxHide.lt-md class="left-section" fxFlex="1 1" fxLayout="column" fxLayoutAlign="end start"></div>

  <div class="right-section" fxFlex.lt-md="100%" fxFlex="30%" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="16px">
    <div fxFlex="80%" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="24px">
      <div class="title">Welkom bij</div>
      <img class="logo" src="assets/logos/new_logo_full.svg" width="330" height="73" alt="Neglect-X Logo" />
      <div class="subtitle">Onze oplossing voor leegstaande, verwaarloosde en verkrotte woningen…</div>
    </div>

    <div fxFill fxFlex="10%" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="16px">
      <button (click)="login()" mat-raised-button type="button" color="primary" class="login-buttons">Aanmelden met Microsoft</button>
      <button *ngIf="!environment.production" (click)="logout()" mat-raised-button type="button" color="primary" class="login-buttons">
        DEBUG LOGOUT
      </button>
    </div>
  </div>
</div>

<span class="version-label"> {{ environment.appVersion }} </span>
