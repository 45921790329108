import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { AdGroupModel } from "src/app/shared/models/user.model";
import { MessageService } from "src/app/shared/services/message.service";
import { UserService } from "src/app/shared/services/user.service";
import { AddAdGroupDialogComponent } from "./add-ad-group-dialog/add-ad-group-dialog.component";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogService } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.service";
import { take, tap } from "rxjs/operators";

@Component({
  selector: "app-ad-groups-admin",
  templateUrl: "./ad-groups.component.html",
})
export class AdGroupsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  displayedColumns = ["Name", "Id", "Actions"];

  allGroups: AdGroupModel[] = [];
  groups: AdGroupModel[] = [];

  groupsDataSource: MatTableDataSource<AdGroupModel> = new MatTableDataSource();

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private matDialog: MatDialog,
    private confirmationService: ConfirmationDialogService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.authService.hasSuperadmin().subscribe(r => {
      if (!r) {
        this.router.navigateByUrl("/");
        return;
      }

      this.getData();
    }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getData(): void {
    this.subscriptions.push(this.userService.getAllGroups().subscribe(r => {
      this.allGroups = r;
    }))

    this.subscriptions.push(this.userService.getGroups().subscribe(r => {
      this.groups = r;
      this.groupsDataSource = new MatTableDataSource(
        this.groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
      );
    }));
  }

  addNewGroup(): void {
    const ref = this.matDialog.open(AddAdGroupDialogComponent, {
      width: "580px",
      data: {
        groups: this.allGroups
      }
    });

    this.subscriptions.push(ref.afterClosed()
      .pipe(
        take(1),
        tap(r => {
          if (!r) {
            return;
          }

          this.groups.push({
            AdId: r.AdId,
            Name: r.Name
          });

          this.groupsDataSource = new MatTableDataSource(
            this.groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
          )
        })
      )
      .subscribe()
    );
  }

  removeGroup(group: AdGroupModel): void {
    this.subscriptions.push(this.messageService.openConfirmationDialog(
        "Groep verwijderen",
        `Weet u zeker dat u de groep ${group.Name} wilt verwijderen?`,
      )
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.groups = this.groups.filter((x) => x.AdId !== group.AdId);
          this.groupsDataSource = new MatTableDataSource(
            this.groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
          );
        }),
      )
      .subscribe()
    );
  }

  save(): void {
    this.subscriptions.push(this.userService.updateGroups(this.groups).subscribe(r => {
      this.messageService.showSnackBarInfo("Groepen bijgewerkt.");

      this.getData();
    }));
  }
}