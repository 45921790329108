<div class="dialog">
  <button class="dialog-close" type="button" mat-mini-fab color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="mat-headline">
          <span>Instelling {{ setting.Key }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button type="submit" [disabled]="saving" mat-raised-button color="primary">Opslaan</button>
        </div>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Key</mat-label>
          <input matInput formControlName="Key" placeholder="Key" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Beschrijving</mat-label>
          <input matInput formControlName="Description" placeholder="Description" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-label>Commentaar</mat-label>
          <input matInput formControlName="Comment" placeholder="Comment" />
        </mat-form-field>
      </div>
      <div>
        <ng-container *ngIf="setting.Type === 'integer'">
          <mat-form-field fxFlex>
            <mat-label>Value</mat-label>
            <input #input type="number" matInput formControlName="Value" placeholder="Value" />
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="setting.Type === 'boolean'">
          <div fxFlex fxLayoutGap="8px">
            <mat-checkbox #input matInput formControlName="Value" placeholder="Value">Value</mat-checkbox>
          </div>
        </ng-container>
        <ng-container *ngIf="setting.Type === 'string'">
          <mat-form-field fxFlex>
            <mat-label>Value</mat-label>
            <input #input matInput formControlName="Value" placeholder="Value" />
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </form>
</div>
