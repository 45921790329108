export class Response {
  Success: boolean;
  Code: string;
  Message: string;
  MessageLevel: ResponseMessageLevel;
  Data: any;
}

export enum ResponseMessageLevel {
  Info,
  Warning,
  Error,
}
