import { AuthService } from '../../auth/auth.service';
import { ImportVlokService } from '../../shared/services/import-vlok.service';
import { Subscription } from 'rxjs';
import { ImportSuspectList } from 'src/app/shared/models/import-suspect-list.model';
import { ImportSuspectService } from '../../shared/services/import-suspect.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, ReplaySubject } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { UIState } from 'src/app/shared/state/ui.state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from 'src/app/shared/components/spinner-dialog/spinner-dialog.component';
import { FilesService } from 'src/app/shared/services/files.service';
import { FileStorageFile } from 'src/app/shared/models/file-storage-file.model';

@Component({
  selector: 'app-vlok-import',
  templateUrl: './vlok-import.component.html',
  styleUrls: ['./vlok-import.component.scss']
})
export class VlokImportComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  uploadingDialogRef: MatDialogRef<SpinnerDialogComponent> = null;

  file: File;
  
  constructor( 
    private messageService: MessageService,
    private importSuspectService: ImportSuspectService,
    private uiState: UIState,
    private router: Router,
    private importVlokService: ImportVlokService,
    private matDialog: MatDialog,
    private filesService: FilesService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs(): void {
    this.uiState.breadcrumbs$.next([
      {
        name: "VLOK Import",
      }
    ]);
  }

  chooseFile(event: any): void {
    if (event.target.files.length === 1) {
      this.setFile(event.target.files[0]);
    }
  }

  dropFile(files: NgxFileDropEntry[]): void {
    if (files.length === 1) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.setFile(file);
      });
    } else if (files.length > 1) {
      this.messageService.showSnackBarError("Je kan maximaal 1 bestand slepen.");
    }
  }

  save() {
    if (!this.authService.hasSuperadmin()) {
      return;
    }

    if (!this.file) {
      this.messageService.showSnackBarError("Gelieve een CSV bestand te uploaden.");
      return;
    }
    const formData = new FormData();
    formData.append('file', this.file);
    this.showUploadSpinner();
    this.subscriptions.push(this.importVlokService.importCsv(formData).subscribe((result: Blob) => {
      if (result) {
        const a = document.createElement("a");
        a.setAttribute("type", "hidden");  
        a.href = window.URL.createObjectURL(result);  
        a.download = `VLOK_Import_Errors.txt`;  
        a.click(); 
        a.remove();
        this.closeUploadSpinner();
        this.messageService.showSnackBarInfo("Importeren gelukt.");
        //this.router.navigateByUrl("/dossiers");
      }
    }, _ => {
      this.closeUploadSpinner();
    }))
  }

  setFile(file: File) {
    if (file.name.split(".")[1].toUpperCase() == "CSV") {
      this.file = file;
    } else {
      this.messageService.showSnackBarError("Bestand is niet van het type .CSV.")
    }
  }

  showUploadSpinner(): void {
    if (this.uploadingDialogRef) {
      return;
    }
    
    this.uploadingDialogRef = this.matDialog.open(SpinnerDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {
        title: "Bezig met importeren"
    }});
  }

  closeUploadSpinner(): void {
    if (!this.uploadingDialogRef) {
      return;
    }

    this.uploadingDialogRef.close();
    this.uploadingDialogRef = null;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
