import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { OnDeactivate } from "../shared/guards/can-deactivate.guard";
import { Dossier } from "../shared/models/dossier.model";
import { OperationType } from "../shared/models/user.model";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[hasPermission]",
})
export class HasPermissionDirective implements OnInit {
  private permissions = [];
  private logicalOp = "AND";
  private isHidden = true;

  constructor(private authService: AuthService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;

    for (const checkPermission of this.permissions) {
      const permissionFound = this.authService.hasPermission(checkPermission) || this.authService.hasRole(checkPermission);

      if (permissionFound) {
        hasPermission = true;

        if (this.logicalOp === "OR") {
          break;
        }
      } else {
        hasPermission = false;

        if (this.logicalOp === "AND") {
          break;
        }
      }
    }

    return hasPermission;
  }
}

@Directive({  
  // tslint:disable-next-line: directive-selector
  selector: "[screenResource]",
})
export class ScreenResourceDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  private screenType: string;
  private operationType: keyof typeof OperationType;
  private dossier: Dossier;

  @Input() set screenResource(type: any[]) {
    this.screenType = type[0];
    this.operationType = type[1];

    if (type.length > 2) {
      this.dossier = type[2];
    }

    this.updateView();
  }

  @Input() set screenResourceOperation(type: keyof typeof OperationType) {
    this.operationType = type;
  }

  private isHidden = true;

  constructor(private authService: AuthService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    this.updateView();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private updateView(): void {
    this.subscriptions.push(this.checkPermission().subscribe(isAllowed => {
      if (isAllowed) {
        if (this.isHidden) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.isHidden = false;
        }
      } else {
        this.isHidden = true;
        this.viewContainer.clear();
      }
    }));
  }

  private checkPermission(): Observable<boolean> {
    if (this.dossier) {
      return this.authService.hasScreenResourceWithDossier(this.screenType, OperationType[this.operationType], this.dossier);
    } else {
      return this.authService.hasScreenResource(this.screenType, OperationType[this.operationType]);
    }
  }
}
