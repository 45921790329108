import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ExportFinancialService } from "../shared/models/export-financial-service.model";
import { Report } from "../shared/models/report.model";
import { ExportGeneration } from "../shared/models/exportGeneration";
import { Response } from "../shared/models/response.model";
import { AppState } from "../shared/state/app.state";
import { SettingExportView } from "../shared/models/setting-export-view.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class ExportOverviewService {
  constructor(private http: HttpClient, private appState: AppState) {}

  getViews(): Observable<Response> {
    return this.http.get<Response>(`${this.appState.baseApiUrl}/export-overview/views`, httpOptions);    
  }

  downloadCsv(view: SettingExportView): void {
    this.http.get(`${this.appState.baseApiUrl}/export-overview/${view?.Id}/download`, { responseType: "blob" }).subscribe((file) => {
      const a = document.createElement("a");
      a.setAttribute("type", "hidden");  
      a.href = window.URL.createObjectURL(file);  
      a.download = `${view.Name}.csv`;  
      a.click(); 
      a.remove();
    });
    
  }
}
