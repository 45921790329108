import { ImportSuspectList } from './../models/import-suspect-list.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../state/app.state';
import { ImportSuspectListLine } from '../models/import-suspect-list-line.model';
import { FileStorageFile } from '../models/file-storage-file.model';
import { take } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: 'root'
})
export class ImportSuspectService {

  constructor(
    private http: HttpClient,
    private appState: AppState,
  ) {}

  getImportLists(): Observable<ImportSuspectList[]> {
    return this.http.get<ImportSuspectList[]>(`${this.appState.baseApiUrl}/vermoedenslijst/import`, httpOptions);
  }

  GetImportListById(id: number): Observable<ImportSuspectList> {
    return this.http.get<ImportSuspectList>(`${this.appState.baseApiUrl}/vermoedenslijst/import/${id}`, httpOptions);
  }

  GetLinesByImportId(id: number): Observable<ImportSuspectListLine[]> {
    return this.http.get<ImportSuspectListLine[]>(`${this.appState.baseApiUrl}/vermoedenslijst/import/${id}/lines`, httpOptions);
  }

  UpdateLine(importSuspectListLine: ImportSuspectListLine): Observable<ImportSuspectListLine> {
    return this.http.put<ImportSuspectListLine>(`${this.appState.baseApiUrl}/vermoedenslijst/import/lines`, importSuspectListLine,  httpOptions);
  }

  import(importSuspectList: ImportSuspectList): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/vermoedenslijst/import`, importSuspectList, httpOptions);
  }

  getPreviewFile(): void {
    this.http.get(`${this.appState.baseApiUrl}/vermoedenslijst/import/file`, { responseType: "blob" })
      .pipe(take(1))
      .subscribe((file: Blob) => {
        const a = document.createElement("a");
        a.hidden = true;
        a.href = window.URL.createObjectURL(file);
        a.download = `Voorbeeldbestand.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(a.href);
      });
  }
}
