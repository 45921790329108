import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { FileStorageFile } from "../models/file-storage-file.model";
import { PhotoMetadata } from "../models/photo-metadata.model";
import { Moduletranslator } from "src/app/shared/helpers/module.translator";
import { AppState } from "../state/app.state";
import { Response } from "../models/response.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class FilesService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient, private moduletranslator: Moduletranslator, private appState: AppState) {}

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  downloadFile(file: FileStorageFile | PhotoMetadata): void {
    if (!file || !file.FileRelativePath) {
      return;
    }

    const path: string = file.FileRelativePath.split("\\").join("/");

    this.subscriptions.push(
      this.http.get(`${this.appState.dataApiUrl}/${path}`, { responseType: "blob" }).subscribe((fileData) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const blob: Blob = new Blob([fileData], { type: file.ContentType });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link: HTMLAnchorElement = document.createElement("a");
        link.href = data;
        link.download = file.FileName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }),
    );
  }

  // Dossier
  getFilesDossier(code: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/dossiers/${code}/files`, httpOptions);
  }

  saveFileDossier(code: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/dossiers/${code}/files`, file);
  }

  deleteFileDossier(code: string, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/dossiers/${code}/files/${fileName}`, httpOptions);
  }

  // Technical report
  getFilesTechnicalReport(module: string, code: string): Observable<FileStorageFile[]> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.get<FileStorageFile[]>(
      `${this.appState.baseApiUrl}/technical-reports/${code}/files/module/${moduleTranslated}`,
      httpOptions,
    );
  }

  saveFileTechnicalReport(module: string, code: string, file: FormData): Observable<any> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.post<any>(`${this.appState.baseApiUrl}/technical-reports/${code}/files/module/${moduleTranslated}`, file);
  }

  deleteFileTechnicalReport(module: string, code: string, fileName: string): Observable<any> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.delete(
      `${this.appState.baseApiUrl}/technical-reports/${code}/files/${fileName}/module/${moduleTranslated}`,
      httpOptions,
    );
  }

  // Administrative act
  getFilesAdministrativeAct(code: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/administrative-acts/${code}/files`, httpOptions);
  }

  saveFileAdministrativeAct(code: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/administrative-acts/${code}/files`, file);
  }

  deleteFileAdministrativeAct(code: string, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/administrative-acts/${code}/files/${fileName}`, httpOptions);
  }

  //// Objection
  getFilesAdministrativeActObjection(code: string, objectionCode: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(
      `${this.appState.baseApiUrl}/administrative-acts/${code}/objections/${objectionCode}/files`,
      httpOptions,
    );
  }

  saveFileAdministrativeActObjection(code: string, objectionCode: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/administrative-acts/${code}/objections/${objectionCode}/files`, file);
  }

  deleteFileAdministrativeActObjection(code: string, objectionCode: string, fileName: string): Observable<any> {
    return this.http.delete(
      `${this.appState.baseApiUrl}/administrative-acts/${code}/objections/${objectionCode}/files/${fileName}`,
      httpOptions,
    );
  }

  // Inventory
  getFilesInventory(module: string, code: string): Observable<FileStorageFile[]> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.get<FileStorageFile[]>(
      `${this.appState.baseApiUrl}/inventories/${code}/files/module/${moduleTranslated}`,
      httpOptions,
    );
  }

  saveFileInventory(module: string, code: string, file: FormData): Observable<any> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.post<any>(`${this.appState.baseApiUrl}/inventories/${code}/files/module/${moduleTranslated}`, file);
  }

  deleteFileInventory(module: string, code: string, fileName: string): Observable<any> {
    const moduleTranslated = this.moduletranslator.moduleTranslationAbbreviation(module);
    return this.http.delete(
      `${this.appState.baseApiUrl}/inventories/${code}/files/${fileName}/module/${moduleTranslated}`,
      httpOptions,
    );
  }

  //SecondResearch
  saveFileSecondResearch(code: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/secondary-residence/${code}/files`, file);
  }

  getFilesSecondResearch(code: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(
      `${this.appState.baseApiUrl}/secondary-residence/${code}/files`,
      httpOptions,
    );
  }

  deleteFileSecondResearch(code: string, fileName: string): Observable<any> {
    return this.http.delete(
      `${this.appState.baseApiUrl}/secondary-residence/${code}/files/${fileName}`,
      httpOptions,
    );
  }

  // Exemption
  getFilesExemption(id: number): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/exemptions/${id}/files`, httpOptions);
  }

  saveFileExemption(id: number, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/exemptions/${id}/files`, file);
  }

  deleteFileExemption(id: number, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/exemptions/${id}/files/${fileName}`, httpOptions);
  }

  // Contact
  getFilesContact(code: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/contacts/${code}/files`, httpOptions);
  }

  saveFileContact(code: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/contacts/${code}/files`, file);
  }

  deleteFileContact(code: string, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/contacts/${code}/files/${fileName}`, httpOptions);
  }

  // Uitbatingsvergunningen
  getFilesUninhabitableLicense(id: string): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/uninhabitable-license/${id}/files`, httpOptions);
  }

  saveFileUninhabitableLicense(id: string, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/uninhabitable-license/${id}/files`, file);
  }

  openFileUninhabitableLicense(id: string, fileName: string): Observable<any> {
    return this.http.get(`${this.appState.baseApiUrl}/uninhabitable-license/${id}/files/${fileName}`, httpOptions);
  }

  deleteFileUninhabitableLicense(id: string, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/uninhabitable-license/${id}/files/${fileName}`, httpOptions);
  }

  // LoketDossiers

  getFilesLoketDossierAll(id: number): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/loket/${id}/all-files`, httpOptions);
  }

  getFilesLoketDossier(id: number): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/loket/${id}/files`, httpOptions);
  }

  saveFileLoketDossier(id: number, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/loket/${id}/files`, file);
  }

  deleteFileLoketDossier(id: number, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/loket/${id}/files/${fileName}`, httpOptions);
  }

  // Template
  getFilesTemplate(id: number): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/activity-template/${id}/files`, httpOptions);
  }

  saveFileTemplate(id: number, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/activity-template/${id}/files`, file);
  }

  deleteFileTemplate(id: number, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/activity-template/${id}/files/${fileName}`, httpOptions);
  }

  //LoketActivities
  getFilesActivity(id: number): Observable<FileStorageFile[]> {
    return this.http.get<FileStorageFile[]>(`${this.appState.baseApiUrl}/loket/activity/${id}/files`, httpOptions);
  }

  saveFileActivity(id: number, file: FormData): Observable<any> {
    return this.http.post<any>(`${this.appState.baseApiUrl}/loket/activity/${id}/files`, file);
  }

  deleteFileActivity(id: number, fileName: string): Observable<any> {
    return this.http.delete(`${this.appState.baseApiUrl}/loket/activity/${id}/files/${fileName}`, httpOptions);
  }

  /////Sharepoint
  getFilesFolderPath(dossierCode: string, category: string, code:string): Observable<Response> {
    return this.http.get<Response>(`${this.appState.baseApiUrl}/files/folder-path/${dossierCode}/${category}/${code}`, httpOptions);
  }

  // Helpers
  isImage(fileName: string): boolean {
    const fileNameArr: string[] = fileName.split(".");
    const fileExtension = `.${fileNameArr[fileNameArr.length - 1]}`;

    return !!fileExtension.match(/.(jpg|jpeg|png|bmp|gif|jfif)$/i);
  }
}
