import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-spinner-dialog",
  templateUrl: "./spinner-dialog.component.html",
  styleUrls: ["./spinner-dialog.component.scss"],
})
export class SpinnerDialogComponent implements OnInit {
  title: string;

  constructor(public matDialogRef: MatDialogRef<SpinnerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.title = data.title;
  }

  ngOnInit(): void {}
}
