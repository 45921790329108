import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { MessageService } from "../services/message.service";

export interface OnDeactivatePayload {
  hasChanges: boolean;
  title?: string;
  text?: string;
  yesLabel?: string;
  onYes?: () => any;
  noLabel?: string;
  onNo?: () => any;
  navigateOnNo?: boolean;
}

export interface OnDeactivate {
  onDeactivate: () => OnDeactivatePayload;
}

@Injectable({
  providedIn: "root",
})
export class CanDeactivateGuard implements CanDeactivate<OnDeactivate> {
  constructor(private messageService: MessageService) {}

  canDeactivate(component: OnDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const payload: OnDeactivatePayload = component.onDeactivate();
    return this.messageService.showUnsavedChanges(payload);
  }
}
