<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Gebruikersbeheer Intergemeentelijke Samenwerking</div>
    <div fxLayoutGap="24px">
      <button mat-raised-button color="primary" (click)="save()" *screenResource="['GEBRUIKERSBEHEER', 'UPDATE']">Opslaan</button>
    </div>
  </div>
</div>
<mat-card fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="48px" class="main" *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
  <div fxFlex="33%" fxLayout="row" class="h-100">
    <div fxFlex="100">
      <div fxLayout="column">
        <div fxFlex="1 1 auto" class="generic-search-field" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>search</mat-icon>
          <input
            #input
            matInput
            [(ngModel)]="filterText"
            (keyup)="applyFilter()"
            placeholder="Zoek op naam of voornaam"
            class="search-input"
          />
          <button *ngIf="filterText != ''" mat-icon-button matTooltip="Zoekopdracht verwijderen" (click)="removeFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="column" class="users-table">
        <div class="title-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
          <div class="mat-title">Gebruikers</div>
          <button mat-raised-button color="primary" (click)="createNewUser()" *screenResource="['GEBRUIKERSBEHEER', 'CREATE']">Nieuw</button>
        </div>
        <div class="table-responsive">
          <table fxFlexFill mat-table [dataSource]="usersDataSource">
            <ng-container matColumnDef="Gebruiker">
              <td mat-cell *matCellDef="let item">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
                  <div>{{ item.Name }} ({{ item.Email }})</div>
                  <button mat-icon-button color="primary">
                    <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr
              mat-row
              class="clickable"
              (click)="toggleUserSelection(row)"
              [class.row-is-clicked]="selectedUser === row"
              *matRowDef="let row; columns: usersTableColumns"
            ></tr>

            <!-- <tr mat-header-row *matHeaderRowDef="usersTableColumns"></tr> -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="usersTableColumns.length">Geen gebruikers.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  <div fxFlex="33%" fxLayout="row" class="h-100 ml-16 overflow-auto">
    <div *ngIf="selectedUser" fxLayout="column" fxFlex="100%">
      
      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 4px;" class="title-wrapper">
        <div class="mat-title">{{ selectedUser.Name }}</div>

        <mat-checkbox [(ngModel)]="selectedUser.IsActive">
          <div>Is Actief</div>
        </mat-checkbox>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 8px;">
        <!-- <mat-form-field fxFlex="35%">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="Email" [(ngModel)]="selectedUser.Email" disabled />
        </mat-form-field> -->

        <mat-form-field fxFlex="40%">
          <mat-label>Rijksregisternummer</mat-label>
          <input matInput placeholder="Rijksregisternummer" [(ngModel)]="selectedUser.RegisterNumber" />
        </mat-form-field>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <div class="mat-title">Besturen</div>
        <button mat-raised-button color="primary" (click)="addNewCommunity()" *screenResource="['GEBRUIKERSBEHEER', 'UPDATE']">Nieuw</button>
      </div>

      <div class="table-responsive">
        <table fxFlexFill mat-table [dataSource]="selectedUserPermissionsDataSource">
          <ng-container matColumnDef="Niscode">
            <th mat-header-cell *matHeaderCellDef>Bestuur</th>
            <td mat-cell *matCellDef="let item">
              {{ getCommunityName(item.Niscode) }} ({{ item.Niscode }})
            </td>
          </ng-container>

          <ng-container matColumnDef="Ambtenaarnaam">
            <th mat-header-cell *matHeaderCellDef>Ambtenaarnaam</th>
            <td mat-cell *matCellDef="let item">
              <ng-container *ngIf="isCommunityIgs(item.Niscode); else isIgs">
                {{ getOfficialName(item) }} ({{ item.OfficialCode }})
              </ng-container>
              <ng-template #isIgs>
                - (IGS)
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <div fxLayout="row" fxLayoutAlign="end center">
                <ng-container *ngIf="isCommunityIgs(item.Niscode)">
                  <button mat-icon-button matTooltip="Aanpassen">
                    <mat-icon>edit</mat-icon>
                  </button>
                </ng-container>
                <button mat-icon-button matTooltip="Verwijderen" (click)="onDeleteClicked($event, item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="permissionTableColumns"></tr>
          <tr 
            class="clickable"
            (click)="togglePermissionSelection(row)"
            [class.row-is-clicked]="selectedPermission === row"
            mat-row
            *matRowDef="let row; columns: permissionTableColumns"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="permissionTableColumns.length">
              Geen besturen gekoppeld.
            </td>
          </tr>
        </table>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"  style="margin-top: 16px;">
        <div class="mat-title">Groepen</div>
        <button mat-raised-button color="primary" (click)="addNewGroup()" *screenResource="['GEBRUIKERSBEHEER', 'UPDATE']">Nieuw</button>
      </div>
      
      <div class="table-responsive">
        <table fxFlexFill mat-table [dataSource]="selectedUserGroupsDataSource">
          <ng-container matColumnDef="Naam">
            <th mat-header-cell *matHeaderCellDef>Naam</th>
            <td mat-cell *matCellDef="let item">
              {{ item.Name.replaceAll("_", " ") }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">
              <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-icon-button matTooltip="Verwijderen" (click)="onDeleteGroupClicked($event, item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="groupTableColumns"></tr>
          <tr 
            mat-row
            *matRowDef="let row; columns: groupTableColumns"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="groupTableColumns.length">
              Geen groepen gekoppeld.
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div fxFlex="33%" fxLayout="row" class="h-100 ml-16 overflow-auto">
    <div *ngIf="selectedPermission" fxLayout="column" fxFlex="100%">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 4px;" class="title-wrapper">
        <div class="mat-title">Rollen voor {{ getCommunityName(selectedPermission.Niscode) }} ({{ selectedPermission.Niscode }})</div>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-form-field fxFlex="33%">
          <mat-label>Ambtenaar</mat-label>
          <mat-select [(ngModel)]="selectedPermission.OfficialCode">
            <mat-option [value]="null">Geen toegang</mat-option>
            <mat-option *ngFor="let option of officials" [value]="option.Code">{{ option.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngFor="let role of roles">
        <mat-checkbox [checked]="hasRole(role.Id)" [disabled]="selectedPermission.OfficialCode === null" (change)="setRole(role.Id, $event)">
          <div>{{ role.Name }}</div>
        </mat-checkbox>
      </ng-container>

      <!-- <div>Selected roles:</div>
      <ul>
        <li *ngFor="let permission of selectedPermission.Roles">
          {{ permission }}
        </li>
      </ul> -->
    </div>
  </div>
</mat-card>
