import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class Moduletranslator {
  moduleTranslation(module: string): string {
    switch (module) {
      case "leegstand":
        return "unoccupied";
      case "verwaarlozing":
        return "neglected";
      case "woningkwaliteit":
        return "uninhabitable";
      default:
        return module;
    }
  }

  moduleTranslationAbbreviation(module: string): string {
    switch (module) {
      case "leegstand":
        return "LS";
      case "unoccupied":
        return "LS";
      case "leegstandVlaanderen":
        return "LSV";
      case "unoccupiedVlaanderen":
        return "LSV";
      case "verwaarlozing":
        return "VW";
      case "neglected":
        return "VW";
      case "verwaarlozingVlaanderen":
        return "VWV";
      case "neglectedVlaanderen":
        return "VWV";
      case "uninhabitable":
        return "OO";
      case "woningkwaliteit":
        return "OO";
      case "tweede-verblijf":
        return "SR";
      default:
        return module;
    }
  }
}
