import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Response } from "../models/response.model";
import { AppState } from "../state/app.state";
import { Logger } from "../helpers/logger";
import { AdGroupModel, Role, User, UserManageModel } from "../models/user.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private appState: AppState, private logger: Logger) {
    this.logger.info("UserService - constructor");
  }

  get(): Observable<User> {
    return this.http.get<User>(`${this.appState.baseApiUrl}/user/me`, httpOptions);
  }

  getRoles(): Observable<Role[]>{
    return this.http.get(`${this.appState.baseApiUrl}/user/roles`, httpOptions)
    .pipe(map((response: Response) => {
      if (!response.Success) { 
        return [];
      }
      return response.Data;
    }));
  }

  getGroups(): Observable<AdGroupModel[]>{
    return this.http.get(`${this.appState.baseApiUrl}/user/groups`, httpOptions)
      .pipe(map((response: Response) => {
        if (!response.Success) { 
          return [];
        }
        return response.Data;
      }));
  }

  getAllGroups(): Observable<AdGroupModel[]>{
    return this.http.get(`${this.appState.baseApiUrl}/user/admin/groups`, httpOptions)
    .pipe(map((response: Response) => {
        if (!response.Success) { 
          return [];
        }
        return response.Data;
      }));
  }

  updateGroups(groups: AdGroupModel[]): Observable<Response>{
    return this.http.post(`${this.appState.baseApiUrl}/user/admin/groups`, groups, httpOptions)
      .pipe(map((response: Response) => {
          if (!response.Success) { 
            return [];
          }
          return response.Data;
        }));
  }

  createUser(body: any): Observable<Response> {
    return this.http.post<Response>(`${this.appState.baseApiUrl}/user/new`, body, httpOptions);
  }

  updateUsers(body: UserManageModel[]): Observable<Response> {
    return this.http.post<Response>(`${this.appState.baseApiUrl}/user/update`, body, httpOptions);
  }

  getUsersWithDetails(): Observable<UserManageModel[]> {
    return this.http.get<UserManageModel[]>(`${this.appState.baseApiUrl}/user/list`, httpOptions);
  }
}
