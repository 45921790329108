import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ConfirmationDialogService {
  constructor(private matDialog: MatDialog) {}

  openConfirmationDialog(title: string, message: string, yesLabel: string = null, noLabel: string = null, optionalLabel: string = null): Observable<any> {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "700px",
      data: {
        title,
        text: message,
        yesLabel,
        noLabel,
        optionalLabel

      },
    });

    return dialogRef.afterClosed();
  }
}
