import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "../services/message.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ResponseMessageLevel } from "../models/response.model";

@Injectable()
export class MessageInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((httpResponse: HttpResponse<any>) => {
        const response = httpResponse.body;
        if (response?.Message) {
          switch (response.MessageLevel) {
            case ResponseMessageLevel.Info:
              this.messageService.showSnackBarInfo(response.Message);
              break;
            case ResponseMessageLevel.Warning:
              this.messageService.showSnackBarWarning(response.Message);
              break;
            case ResponseMessageLevel.Error:
              this.messageService.showSnackBarError(response.Message);
              break;
          }
        }
      }),
    );
  }
}
