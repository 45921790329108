import { BehaviorSubject, Subscription } from "rxjs";
import { Breadcrumb } from "../models/app.models";
import { Injectable, OnDestroy } from "@angular/core";
import { Logger } from "../helpers/logger";
import { MediaMatcher } from "@angular/cdk/layout";
import { StorageService } from "../services/storage.service";
import { UISettings } from "../models/ui-settings.model";

@Injectable({
  providedIn: "root",
})
export class UIState implements OnDestroy {
  private subscriptions: Subscription[] = [];
  listFilterStringOwners$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  reportsCurrentType$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  showSideNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showInventoryChips$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sideNavModeMediaQuery: MediaQueryList;
  breadcrumbs$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  ownerslistFilterString$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  settings: UISettings;

   public readonly timePattern = {
    '0': { pattern: new RegExp(/[0-2]/) },
    '1': { pattern: new RegExp(/[0-9]/) },
    '2': { pattern: new RegExp(/[0-5]/) }
  };

  constructor(private storageService: StorageService, private media: MediaMatcher) {
    // Retrieve cached state from local storage
    const showSideNavCache: boolean = this.storageService.get("nx.ui.showSideNav");
    this.showSideNav$.next(showSideNavCache);
    this.subscriptions.push(
      this.showSideNav$.subscribe((show) => {
        // Cache state to local storage
        this.storageService.set("nx.ui.showSideNav", show);
      }),
    );

    this.sideNavModeMediaQuery = this.media.matchMedia("(max-width: 1300px)");

    // This can be disabled, just for debugging
    this.subscriptions.push(
      this.breadcrumbs$.subscribe((breadcrumbs: Breadcrumb[]) => {
        // this.logger.log("breadcrumbs", breadcrumbs);
      }),
    );

    this.loadSettings();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleSideNav(): void {
    this.showSideNav$.next(!this.showSideNav$.value);
  }

  private static readonly SETTINGS_KEY = "uiSettings";

  loadSettings(): void {
    if (!this.storageService.get(UIState.SETTINGS_KEY)) {
      this.storageService.set(UIState.SETTINGS_KEY, JSON.stringify(new UISettings()));
    }

    this.settings = new UISettings();
    Object.assign(this.settings, JSON.parse(this.storageService.get(UIState.SETTINGS_KEY)));
  }

  saveSettings(): void {
    this.storageService.set(UIState.SETTINGS_KEY, JSON.stringify(this.settings));
  }
}
