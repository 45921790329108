import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  text: string;
  yesLabel: string;
  noLabel: string;
  optionalLabel: string;

  constructor(public matDialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.text = data.text;
    this.yesLabel = data.yesLabel;
    this.noLabel = data.noLabel;
    this.optionalLabel = data.optionalLabel;
  }

  ngOnInit(): void {}
}
