import { Component, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent {
  @Input() width: string = "100%";
  @Input() height: string = "20px";
  @Input() borderRadius: string = "4px";
  @Input() margin: string = "0";
}
