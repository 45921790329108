import { AppModule } from "./app/app.module";
import { enableProdMode, LOCALE_ID } from "@angular/core";
import { environment } from "./environments/environment";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import "@angular/compiler"; // Elastic APM

import { defineCustomElements } from "@ionic/pwa-elements/loader";

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [{ provide: LOCALE_ID, useValue: "nl-BE" }],
  })
  .catch((err) => console.error(err));

if (environment.production) {
  enableProdMode();
}

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
