<div class="dialog">
  <button class="dialog-close" type="button" mat-mini-fab color="primary" (click)="close(null)">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="mat-headline">
          <span>Nieuwe gebruiker</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button type="submit" [disabled]="saving" mat-raised-button color="primary">Opslaan</button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field fxFlex="50%">
          <mat-label>Voornaam *</mat-label>
          <input
            matInput
            formControlName="FirstName"
            cdkFocusInitial
          />
        </mat-form-field>
        <mat-form-field fxFlex="50%">
          <mat-label>Achternaam *</mat-label>
          <input
            matInput
            formControlName="LastName"
            cdkFocusInitial
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field fxFlex="100%">
          <mat-label>Email-adres *</mat-label>
          <input
            matInput
            formControlName="Email"
            cdkFocusInitial
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field fxFlex="50%">
          <mat-label>Bedrijfsnaam *</mat-label>
          <input
            matInput
            formControlName="CompanyName"
            cdkFocusInitial
          />
        </mat-form-field>
        <mat-form-field fxFlex="50%">
          <mat-label>Rijksregisternummer</mat-label>
          <input
            matInput
            formControlName="RegisterNumber"
            cdkFocusInitial
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
