import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { take, tap } from "rxjs/operators";
import { MessageService } from "src/app/shared/services/message.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-add-user-dialog",
  templateUrl: "./add-user-dialog.component.html",
  styleUrls: ["./add-user-dialog.component.scss"],
})
export class AddUserDialogComponent {
  saving: boolean;

  form: FormGroup = this.fb.group({
    FirstName: [null, Validators.required],
    LastName: [null, Validators.required],
    Email: [null, Validators.required],
    CompanyName: [null, Validators.required],
    RegisterNumber: [null],
  });

  constructor(
    public matDialogRef: MatDialogRef<AddUserDialogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
  ) {}

  close(result: any): void {
    this.matDialogRef.close(result);
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.userService
      .createUser(this.form.getRawValue())
      .pipe(
        take(1),
        tap((r) => {
          if (!r.Success) {
            this.messageService.showSnackBarError(r.Message);
            return;
          }

          this.messageService.showSnackBarInfo(`Gebruiker '${r.Data.Name}' toegevoegd.`);
          this.close(r.Data);
        }),
      )
      .subscribe();
  }
}
