<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Instellingen</div>
  </div>
</div>
<div fxLayout="column" fxLayoutAlign="start start" class="page-content" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="16px" fxFill>
    <div fxFlex="1 1 auto" class="generic-search-field" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *appSkeletonLoader="{ loading: loading, amountOfRows: 1, height: '32px', minWidth:'20%', maxWidth: '90%'}">
      <mat-icon>search</mat-icon>
      <input #filterInput matInput [(ngModel)]="filterText" placeholder="Zoek op instelling" class="search-input" />
      <button *ngIf="filterText != ''" mat-icon-button matTooltip="Zoekopdracht verwijderen" (click)="removeFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="column" fxLayoutGap="16px" *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
      <mat-accordion multi displayMode="flat">
        <ng-container *ngFor="let group of filteredSettingGroups">
          <mat-expansion-panel [expanded]="group.expanded" [togglePosition]="'before'">
            <mat-expansion-panel-header>{{ group.name }}</mat-expansion-panel-header>

            <div class="table-responsive">
              <table
                fxFlexFill
                mat-table
                [dataSource]="group.dataSource"
                matSort
                matSortActive="Description"
                matSortDirection="asc"
                (matSortChange)="onSortData($event, group)"
              >
                <ng-container matColumnDef="Key">
                  <th class="w-100" mat-header-cell *matHeaderCellDef mat-sort-header>Key</th>
                  <td mat-cell *matCellDef="let setting">{{ setting.Key }}</td>
                </ng-container>

                <ng-container matColumnDef="Description">
                  <th class="w-100" mat-header-cell *matHeaderCellDef mat-sort-header>Beschrijving</th>
                  <td mat-cell *matCellDef="let setting">{{ setting.Description }}</td>
                </ng-container>

                <ng-container matColumnDef="Value">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
                  <td mat-cell *matCellDef="let setting">{{ setting.Value }}</td>
                </ng-container>

                <ng-container matColumnDef="Comment">
                  <th mat-header-cell *matHeaderCellDef>Opmerking</th>
                  <td mat-cell *matCellDef="let setting">{{ setting.Comment }}</td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef class="actions"></th>
                  <td mat-cell *matCellDef="let dossier">
                    <div fxLayout="row" fxLayoutAlign="end center">
                      <button mat-icon-button matTooltip="Bewerken"><mat-icon>edit</mat-icon></button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  (click)="openSettingsDialog(setting)"
                  [style.border-bottom]="'1.5px solid #4f606f'"
                  class="clickable"
                  mat-row
                  *matRowDef="let setting; columns: displayedColumns"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" [attr.colspan]="displayedColumns.length">Geen instellingen gevonden.</td>
                </tr>
              </table>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
</div>
