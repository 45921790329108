<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Overzichten</div>
    <div fxFlex="1 1 auto" class="generic-search-field" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon>search</mat-icon>
      <input #input matInput [(ngModel)]="filterText" (keyup)="applyFilter()" placeholder="Zoeken" />
      <button *ngIf="filterText != ''" mat-icon-button matTooltip="Zoekopdracht verwijderen" (click)="removeFilter()"><mat-icon>close</mat-icon></button>
    </div>
      <div fxFlex="135px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"></div>
  </div>
  <div class="page-content" *appSkeletonLoader="{ loading: loading, amountOfRows: 10, height: '32px', minWidth:'20%', maxWidth:'90%'}">
    <ng-container>
      <div class="table-responsive">
        <table fxFlexFill mat-table [dataSource]="dataSource" matSort matSortActive="Code" matSortDirection="desc">
          <ng-container matColumnDef="Name">
            <th  mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
            <td mat-cell *matCellDef="let exportView">{{ exportView.Name }}</td>
          </ng-container>
          <ng-container matColumnDef="Actions" >
            <th mat-header-cell *matHeaderCellDef class="actions"></th>
            <td mat-cell *matCellDef="let exportView">
              <ng-container>
                <div fxLayout="row" fxLayoutAlign="end center">
                  <button mat-icon-button (click)="exportOverviewService.downloadCsv(exportView)" matTooltip="downloaden"><mat-icon>download</mat-icon></button>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let dossier; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td *ngIf="loading" class="mat-cell" [attr.colspan]="displayedColumns.length">Laden...</td>
            <td *ngIf="!loading" class="mat-cell" [attr.colspan]="displayedColumns.length">Geen overzichten gevonden.</td>
          </tr>
        </table>
      </div>
      <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </ng-container>
  </div>
</div>
