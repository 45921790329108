import { Directive, Input, TemplateRef, ViewContainerRef, ComponentFactoryResolver, Injector, EmbeddedViewRef, ComponentRef } from "@angular/core";
import { SkeletonLoaderComponent } from "../components/skeleton-loader/skeleton-loader.component";
import { MatCard } from "@angular/material/card";

@Directive({
  selector: "[appSkeletonLoader]",
})
export class SkeletonLoaderDirective {
  contentViewRef: EmbeddedViewRef<any>;
  skeletonRefs: ComponentRef<any>[] = [];

  isNgContainer: boolean = false;

  defaultVisibility: string;
  defaultPosition: string;

  @Input() set appSkeletonLoader({
    loading,
    amountOfRows = 1,
    width = "",
    height = "20px",
    margin = "12px",
    minWidth = "150px",
    maxWidth = "300px",
    inMatCard = false,
  }: {
    loading: boolean;
    amountOfRows?: number;
    width?: string;
    height?: string;
    margin?: string;
    minWidth?: string;
    maxWidth?: string;
    inMatCard?: boolean;
  }) {
    if (loading) {
      if (this.isNgContainer) {
        this.viewContainer.clear();
      } else {
        this.contentViewRef.rootNodes[0].style.visibility = "hidden";
        this.contentViewRef.rootNodes[0].style.position = "absolute";
      }
      
      if (inMatCard) {
        this.createMatCardSkeletons(amountOfRows, width, height, margin, minWidth, maxWidth);
      } else {
        this.createSkeletons(amountOfRows, width, height, margin, minWidth, maxWidth);
      }
    } else {
      if (this.isNgContainer) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.skeletonRefs.forEach(x => x.destroy());
        this.contentViewRef.rootNodes[0].style.visibility = this.defaultVisibility;
        this.contentViewRef.rootNodes[0].style.position = this.defaultPosition;
      }
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {
    this.contentViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    this.isNgContainer = !this.contentViewRef.rootNodes[0].style;

    if (!this.isNgContainer) {
      this.defaultVisibility = this.contentViewRef.rootNodes[0].style.visibility;
      this.defaultPosition = this.contentViewRef.rootNodes[0].style.position;
    }
  }

  private createSkeletons(
    amountOfRows: number,
    width: string,
    height: string,
    margin: string,
    minWidth: string,
    maxWidth: string,
  ): void {
    // Vaste width voor titel
    if (amountOfRows > 1) {
      const skeletonFactory = this.resolver.resolveComponentFactory(SkeletonLoaderComponent);
      const skeletonRef = this.viewContainer.createComponent(skeletonFactory, undefined, this.injector);
      const titleHeight = this.getTitleHeight(height);
      skeletonRef.instance.width = "20%";
      skeletonRef.instance.height = titleHeight;
      skeletonRef.instance.margin = margin;

      this.skeletonRefs.push(skeletonRef);
    }

    for (let i = amountOfRows > 1 ? 1 : 0; i < amountOfRows; i++) {
      const skeletonFactory = this.resolver.resolveComponentFactory(SkeletonLoaderComponent);
      const skeletonRef = this.viewContainer.createComponent(skeletonFactory, undefined, this.injector);

      skeletonRef.instance.width = width || this.getRandomWidth(minWidth, maxWidth);
      skeletonRef.instance.height = height;
      skeletonRef.instance.margin = margin;

      this.skeletonRefs.push(skeletonRef);
    }
  }

  private createMatCardSkeletons(
    amountOfRows: number,
    width: string,
    height: string,
    margin: string,
    minWidth: string,
    maxWidth: string,
  ): void {
    const matCardFactory = this.resolver.resolveComponentFactory(MatCard);
    const matCardRef = this.viewContainer.createComponent(matCardFactory);

    this.skeletonRefs.push(matCardRef);

    const matCardElement = (matCardRef.hostView as any).rootNodes[0] as HTMLElement;
    matCardElement.classList.add("page-section");
    this.viewContainer.insert(matCardRef.hostView);

    if (amountOfRows > 1) {
      const titleHeight = this.getTitleHeight(height);
      this.addSkeletonToElement(matCardElement, "15%", titleHeight, margin);
    }
    for (let i = amountOfRows > 1 ? 1 : 0; i < amountOfRows; i++) {
      this.addSkeletonToElement(matCardElement, width || this.getRandomWidth(minWidth, maxWidth), height, margin);
    }
  }

  private addSkeletonToElement(parentElement: HTMLElement, width: string, height: string, margin: string): void {
    const skeletonFactory = this.resolver.resolveComponentFactory(SkeletonLoaderComponent);
    const skeletonRef = this.viewContainer.createComponent(skeletonFactory, undefined, this.injector);

    skeletonRef.instance.width = width;
    skeletonRef.instance.height = height;
    skeletonRef.instance.margin = margin;

    const skeletonElement = (skeletonRef.hostView as any).rootNodes[0];
    parentElement.appendChild(skeletonElement);
  }

  private getTitleHeight(height: string): string {
    const heightValue = parseInt(height.replace("px", ""), 10);
    return `${heightValue + 6}px`;
  }

  private getRandomWidth(minWidth: string, maxWidth: string): string {
    if (this.isPercentage(minWidth) || this.isPercentage(maxWidth)) {
      return `${this.getRandomPercentage(minWidth, maxWidth)}%`;
    }

    const min = parseInt(minWidth.replace("px", ""), 10);
    const max = parseInt(maxWidth.replace("px", ""), 10);
    const randomWidth = Math.floor(Math.random() * (max - min + 1)) + min;
    return `${randomWidth}px`;
  }

  private isPercentage(value: string): boolean {
    return value.includes("%");
  }

  private getRandomPercentage(minWidth: string, maxWidth: string): number {
    const min = parseInt(minWidth.replace("%", ""), 10);
    const max = parseInt(maxWidth.replace("%", ""), 10);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
