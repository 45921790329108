import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-auth-sso-callback",
  templateUrl: "./sso-callback.component.html",
  styleUrls: ["./sso-callback.component.scss"],
})
export class AuthSSOCallbackComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.loginWithSSOCallback();
  }
}
