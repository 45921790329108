import { Injectable, Optional } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { OAuthModuleConfig, OAuthStorage } from "angular-oauth2-oidc";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage, @Optional() private moduleConfig: OAuthModuleConfig) {}

  private checkUrl(url: string): boolean {
    // use appstate baseurl?
    const found = this.moduleConfig.resourceServer.allowedUrls.find((u) => url.startsWith(u));
    return !!found;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (!this.moduleConfig) {
      return next.handle(req);
    }
    if (!this.moduleConfig.resourceServer) {
      return next.handle(req);
    }
    if (!this.moduleConfig.resourceServer.allowedUrls) {
      return next.handle(req);
    }
    // TODO urls not defined in enviroment
    // if (!this.checkUrl(url)) {
    //   return next.handle(req);
    // }

    const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;

    if (sendAccessToken) {
      const token = this.authStorage.getItem("access_token");

      const header = "Bearer " + token;

      const headers = req.headers.set("Authorization", header);

      req = req.clone({ headers });
    }

    return next.handle(req);
  }
}
