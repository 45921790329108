import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const selectedCommunity: string = localStorage.getItem("nx.app.community");

    if (selectedCommunity) {
      req = req.clone({
        setHeaders: {
          "NX-NIS-CODE": selectedCommunity,
        },
      });
    }

    return next.handle(req);
  }
}
