<div class="page-background-block"></div>
<div>
  <div fxLayout="column" fxLayoutAlign="start">
    <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <div class="mat-display-1 page-title">VLOK Import starten</div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <ng-container *screenResource="['VLOK_IMPORT', 'CREATE']">
          <button [disabled]="!file" type="submit" (click)="save()" mat-raised-button color="primary">Starten</button>
        </ng-container>
      </div>
    </div>
  </div>
  <mat-card class="main">
    <ng-container *screenResource="['VLOK_IMPORT', 'CREATE']">
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px">
        <div fxFlex="1 1 auto">
          <ngx-file-drop
            [multiple]="false"
            dropZoneLabel="Sleep te importeren CSV-bestand naar hier"
            dropZoneClassName="ngx-file-drop-zone"
            contentClassName="ngx-file-content"
            (onFileDrop)="dropFile($event)"
          >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div fxFlexFill *ngIf="!file" fxLayout="column" fxLayoutAlign="center center">
                Sleep te importeren CSV-bestand naar hier
              </div>
              <div fxFlexFill *ngIf="file" fxLayout="column" fxLayoutAlign="center center">{{ file.name }}</div>
            </ng-template>
          </ngx-file-drop>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <button (click)="chooseFilesButton.click()" type="button" mat-raised-button color="primary">Bestand kiezen</button>
          <input
            #chooseFilesButton
            type="file"
            style="display: none"
            (change)="chooseFile($event)"
            [multiple]="false"
            accept=".csv"
          />
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="25px" *ngIf="authService.hasSuperadmin() | async">
        <mat-checkbox fxFlex="50%">General import</mat-checkbox>
      </div>
    </ng-container>
  </mat-card>
</div>
