import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoketState } from 'src/app/loket/loket.state';
import { ContactPerson } from '../models/contact-person.model';
import { Dossier } from '../models/dossier.model';
import { LoketContactMoment } from '../models/loket-contact-moment.model';
import { LoketDossier } from '../models/loket-dossier.model';
import { LoketLogBook } from '../models/loket-Log-book.model';
import { LoketPartner } from '../models/loket-partner.model';
import { LoketStatus } from '../models/loket-status.model';
import { LoketTask } from '../models/loket-task.model';
import { TypeInvolved } from '../models/type-involved.model';
import { AppState } from '../state/app.state';
import { LoketContactMomentStatus } from '../models/loket-contact-moment-status.model';
import { tap } from 'rxjs/operators';
import { LoketContactMomentType } from '../models/loket-contact-moment-type.model';
import { LoketContactMomentMail } from '../models/loket-contact-moment-mail.model';
import { ContactMomentCalendarItem } from '../models/loket-contact-moment-calendar-item.model';
import { LoketContactMomentCalendar } from '../models/loket-contact-moment-calendar.model';
import { Moment } from 'moment';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class LoketService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient, private appState: AppState, private loketState: LoketState ) {} 

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getLoketDossiers(): Observable<LoketDossier[]> {
    return this.http.get<LoketDossier[]>(`${this.appState.baseApiUrl}/loket`, httpOptions);
  }

  getLoketDossierById(id: number): Observable<LoketDossier> {
    return this.http.get<LoketDossier>(`${this.appState.baseApiUrl}/loket/dossier/${id}`, httpOptions);
  }

  searchLoketDossiers(search: string): Observable<LoketDossier[]> {
    return this.http.get<LoketDossier[]>(`${this.appState.baseApiUrl}/loket/${search}`, httpOptions);
  }

  getLoketDossiersByVolCode(volCode: string): Observable<LoketDossier[]> {
    return this.http.get<LoketDossier[]>(`${this.appState.baseApiUrl}/loket/vol/${volCode}`, httpOptions);
  }

  getLoketDossiersByAddress(dossier: LoketDossier): Observable<LoketDossier[]> {
    return this.http.put<LoketDossier[]>(`${this.appState.baseApiUrl}/loket/address`,dossier, httpOptions);
  }

  upsertLoketDossier(dossier: LoketDossier): Observable<LoketDossier> {
    return this.http.put<LoketDossier>(`${this.appState.baseApiUrl}/loket`, dossier, httpOptions).pipe(
      tap((response: LoketDossier) => {
        if (!response) return;
        this.appState.addlastTouchedLoketDossiers(response);
      }),
    );
  }

  getLoketDetail(id: number): Observable<LoketDossier> {
    return this.http.get<LoketDossier>(`${this.appState.baseApiUrl}/loket/${id}`, httpOptions).pipe(
      tap((response: LoketDossier) => {
        if (!response) return;
        this.appState.addDossierToLastVisitLoketDossiers(response);
      }),
    );
  }

  deleteLoketById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/${id}`, httpOptions);
  }

  getLinkableVolDossier(dossier: LoketDossier): Observable<Dossier> {
    return this.http.post<Dossier>(`${this.appState.baseApiUrl}/loket/linkable-vol-dossier`,dossier, httpOptions);
  }

  unlinkVolDossier(dossier: LoketDossier): Observable<LoketDossier> {
    return this.http.put<LoketDossier>(`${this.appState.baseApiUrl}/loket/unlink-vol-dossier`,dossier, httpOptions);
  }

  linkVolDossier(dossier: LoketDossier, volCode: string): Observable<LoketDossier> {
    return this.http.put<LoketDossier>(`${this.appState.baseApiUrl}/loket/link-vol-dossier/${volCode}`,dossier, httpOptions);
  }

  getStatusList(): Observable<LoketStatus[]> {
    return this.http.get<LoketStatus[]>(`${this.appState.baseApiUrl}/loket/status`, httpOptions);
  }

  getContactMoments(): Observable<LoketContactMoment[]> {
    return this.http.get<LoketContactMoment[]>(`${this.appState.baseApiUrl}/loket/contact-moment`, httpOptions);
  }

  getContactMomentCalendarItems(): Observable<LoketContactMoment[]> {
    return this.http.get<LoketContactMoment[]>(`${this.appState.baseApiUrl}/loket/contact-moment-calendar`, httpOptions);
  }

  getContactMomentsFromDay(date: Moment): Observable<ContactMomentCalendarItem[]> {
    return this.http.get<ContactMomentCalendarItem[]>(`${this.appState.baseApiUrl}/loket/contact-moment/${date.format("MM-DD-YYYY")}`, httpOptions);
  }

  getContactMomentsByLokId(id: number): Observable<LoketContactMoment[]> {
    return this.http.get<LoketContactMoment[]>(`${this.appState.baseApiUrl}/loket/contact-moment/lok/${id}`, httpOptions);
  }

  getContactMomentById(id: number): Observable<LoketContactMoment> {
    return this.http.get<LoketContactMoment>(`${this.appState.baseApiUrl}/loket/contact-moment/${id}`, httpOptions);
  }

  upsertContactMoment(contactMoment: LoketContactMoment): Observable<LoketContactMoment> {
    return this.http.put<LoketContactMoment>(`${this.appState.baseApiUrl}/loket/contact-moment`, contactMoment, httpOptions);
  }

  createDossierFromContactMomentId(id: number): Observable<number> {
    return this.http.post<number>(`${this.appState.baseApiUrl}/loket/contact-moment/create-dossier/${id}`, httpOptions);
  }

  updateContactMomentCalendar(contactMoment: LoketContactMoment): Observable<LoketContactMoment> {
    return this.http.put<LoketContactMoment>(`${this.appState.baseApiUrl}/loket/contact-moment/time-change`, contactMoment, httpOptions);
  }

  upsertContactMomentCalendar(contactMoment: LoketContactMomentCalendar): Observable<any> {
    return this.http.put<any>(`${this.appState.baseApiUrl}/loket/contact-moment-calendar`, contactMoment, httpOptions);
  }

  upsertNextContactMomentCalendar(contactMoment: LoketContactMomentCalendar): Observable<any> {
    return this.http.put<any>(`${this.appState.baseApiUrl}/loket/contact-moment-calendar-next`, contactMoment, httpOptions);
  }

  sentMailContactMoment(contactMoment: LoketContactMoment): Observable<any> {
    return this.http.put<any>(`${this.appState.baseApiUrl}/loket/contact-moment/send-mail`, contactMoment, httpOptions);
  }

  downloadMailContactMoment(id: string): void {
    this.subscriptions.push(this.http.get(`${this.appState.baseApiUrl}/loket/contact-moment/${encodeURIComponent(id)}/mail/download`, { responseType: "blob" }).subscribe((file) => {
      const a = document.createElement("a");
      a.setAttribute("type", "hidden");  
      a.href = window.URL.createObjectURL(file);  
      a.download = `mail.eml`;  
      a.click(); 
      a.remove();
    }));
  }

  getContactMomentMailByMomentId(id: number): Observable<LoketContactMomentMail[]> {
    return this.http.get<LoketContactMomentMail[]>(`${this.appState.baseApiUrl}/loket/contact-moment/${id}/mails`, httpOptions);
  }

  deleteContactMomentById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/contact-moment/${id}`, httpOptions);
  }

  getContactMomentSatuses(): Observable<LoketContactMomentStatus[]> {
    return this.http.get<LoketContactMomentStatus[]>(`${this.appState.baseApiUrl}/loket/contact-moment/status`, httpOptions);
  }

  getContactMomentSatusesByTypeId(id: number): Observable<LoketContactMomentStatus[]> {
    return this.http.get<LoketContactMomentStatus[]>(`${this.appState.baseApiUrl}/loket/contact-moment/status/type/${id}`, httpOptions);
  }

  getContactMomentTypes(): Observable<LoketContactMomentType[]> {
    return this.http.get<LoketContactMomentType[]>(`${this.appState.baseApiUrl}/loket/contact-moment/type`, httpOptions);
  }
  
  getTasksByLokId(id: number): Observable<LoketTask[]> {
    return this.http.get<LoketTask[]>(`${this.appState.baseApiUrl}/loket/task/lok/${id}`, httpOptions);
  }

  getTaskById(id: number): Observable<LoketTask> {
    return this.http.get<LoketTask>(`${this.appState.baseApiUrl}/loket/task/${id}`, httpOptions);
  }

  upsertTask(task: LoketTask): Observable<LoketTask> {
    return this.http.put<LoketTask>(`${this.appState.baseApiUrl}/loket/task`, task, httpOptions);
  }

  deleteTaskById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/task/${id}`, httpOptions);
  }

  getLogBooksByLokId(id: number): Observable<LoketLogBook[]> {
    return this.http.get<LoketLogBook[]>(`${this.appState.baseApiUrl}/loket/logbook/lok/${id}`, httpOptions);
  }

  getLogBookById(id: number): Observable<LoketLogBook> {
    return this.http.get<LoketLogBook>(`${this.appState.baseApiUrl}/loket/logbook/${id}`, httpOptions);
  }

  upsertLogBook(logbook: LoketLogBook): Observable<LoketLogBook> {
    return this.http.put<LoketLogBook>(`${this.appState.baseApiUrl}/loket/logbook`, logbook, httpOptions);
  }

  deleteLogBookById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/logbook/${id}`, httpOptions);
  }

  searchPerson(person: ContactPerson): Observable<ContactPerson[]> {
    return this.http.post<ContactPerson[]>(`${this.appState.baseApiUrl}/loket/contact-person/search`,person, httpOptions);
  }

  upsertContactPerson(contactPerson: ContactPerson): Observable<ContactPerson> {
    return this.http.put<ContactPerson>(`${this.appState.baseApiUrl}/loket/contact-person/`, contactPerson, httpOptions);
  }

  getContactPersonById(id: string): Observable<ContactPerson> {
    return this.http.get<ContactPerson>(`${this.appState.baseApiUrl}/loket/contact-person/${id}`, httpOptions);
  }

  getContactPersons(): Observable<ContactPerson[]> {
    return this.http.get<ContactPerson[]>(`${this.appState.baseApiUrl}/loket/contact-persons`, httpOptions);
  }

  DeleteContactPersonById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/contact-person/${id}`, httpOptions);
  }

  getContactPersonByLokId(id: number): Observable<ContactPerson[]> {
    return this.http.get<ContactPerson[]>(`${this.appState.baseApiUrl}/loket/contact-person/lok/${id}`, httpOptions);
  }

  setMainContactPerson(loketId: number, contactPerson: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.appState.baseApiUrl}/loket/contact-person/${loketId}/${contactPerson}`, httpOptions);
  }

  removeMainContactPerson(loketId: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.appState.baseApiUrl}/loket/contact-person/${loketId}`, httpOptions);
  }

  getInvolvedTypes(): Observable<TypeInvolved[]> {
    return this.http.get<TypeInvolved[]>(`${this.appState.baseApiUrl}/loket/involved-types/`, httpOptions);
  }

  upsertPartner(partner: LoketPartner): Observable<LoketPartner> {
    return this.http.put<LoketPartner>(`${this.appState.baseApiUrl}/loket/partners`, partner, httpOptions);
  }

  deletePartnerById(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.appState.baseApiUrl}/loket/partners/${id}`, httpOptions);
  }

  getPartnerActiveList(): Observable<LoketPartner[]> {
    return this.http.get<LoketPartner[]>(`${this.appState.baseApiUrl}/loket/partners/active`, httpOptions);
  }

  getPartnerList(): Observable<LoketPartner[]> {
    return this.http.get<LoketPartner[]>(`${this.appState.baseApiUrl}/loket/partners`, httpOptions);
  }

  downloadEid(platform: string, arch: string): Observable<Blob> {
    return this.http.get(`${this.appState.baseApiUrl}/loket/installer/${platform}/${arch}`, { responseType: "blob" });
  }
}
