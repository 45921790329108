import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AdGroupModel } from "src/app/shared/models/user.model";

@Component({
  selector: "app-add-group-dialog",
  templateUrl: "./add-group-dialog.component.html",
  styleUrls: ["./add-group-dialog.component.scss"],
})
export class AddGroupDialogComponent {
  saving: boolean;

  form: FormGroup = this.fb.group({
    AdId: [null, Validators.required],
    Name: [null]
  });

  groups: AdGroupModel[];

  constructor(
    public matDialogRef: MatDialogRef<AddGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
  ) {
    this.groups = data.groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1));
  }

  onGroupChanged(): void {
    const groupId = this.form.get("AdId").value;
    if (!groupId) {
      return;
    }
    
    const groups = this.groups.filter(x => x.AdId === groupId);
    if (groups.length === 0) {
      return;
    }

    this.form.get("Name").setValue(groups[0].Name);
  }

  close(result: any): void {
    this.matDialogRef.close(result);
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.close(this.form.getRawValue());
  }
}
