import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "src/app/shared/state/app.state";
import { CompanySettingsVM, Setting } from "../../shared/models/setting.model";
import { SettingsService } from "../../shared/services/settings.service";
import { Subscription } from "rxjs";
import { UIState } from "../../shared/state/ui.state";
import { AuthService } from "../../auth/auth.service";
import { OnDeactivate, OnDeactivatePayload } from "../../shared/guards/can-deactivate.guard";
import { environment } from "src/environments/environment";
import { MessageService } from "../../shared/services/message.service";
import { OperationType } from "src/app/shared/models/user.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-company-details",
  templateUrl: "./company-details.component.html",
  styleUrls: ["./company-details.component.scss"],
})
export class CompanyDetailsComponent implements OnInit, OnDeactivate, OnDestroy {
  environment = environment;

  private subscriptions: Subscription[] = [];
  
  saving: boolean;
  loading: boolean = false;

  settings: CompanySettingsVM;

  form: FormGroup = this.fb.group({
    Niscode: [null],
    Name: [null],
    ServiceCell: [null],
    Street: [null],
    HouseNumber: [null],
    PostalCode: [null],
    City: [null],
    Telephone: [null],
    Fax: [null],
    HasInventory: [false],

    CadastralSurfaceEnabled: [false],
    TotalSurfaceEnabled: [false],
    BuildingLayersEnabled: [false],
    FloorSurfaceEnabled: [false],
    SideLengthEnabled: [false],
    TotalStreetsOnSideEnabled: [false],
    LocationTypeEnabled: [false],
    DistrictEnabled: [false]
  });

  constructor(
    public appState: AppState,
    public authService: AuthService,
    public uiState: UIState,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.appState.allowedToChangeCommunity = false;

    if (!this.appState.activeCommunity$.value?.IsCommunity) {
      this.router.navigateByUrl("/");
    }
  
    this.uiState.breadcrumbs$.next([
      {
        name: "Bedrijfsgegevens",
      },
    ]);

    this.subscriptions.push(this.authService.hasScreenResource("BEDRIJFSGEGEVENS", OperationType.UPDATE).subscribe(hasPermissions => {
      if (!hasPermissions) {
        this.form.disable();
      }
  
      this.getSettings();
    }));
  }

  onDeactivate(): OnDeactivatePayload {
    return {
      hasChanges: this.form.dirty,
    };
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  save(): void {
    if (this.saving) {
      return;
    }

    if (this.form.invalid) {
      this.messageService.showSnackBarError("Gelieve alle verplichte velden in te vullen.");
      return;
    }

    this.saving = true;

    const settings = { ...this.settings, ...this.form.getRawValue() };
    this.subscriptions.push(
      this.settingsService.updateCompanySettings(settings).subscribe(
        (settings: Setting[]) => {
          this.saving = false;

          this.getSettings();
        },
        (error: any) => {
          this.saving = false;
        },
      ),
    );
  }

  getSettings(): void {
    this.loading = true;
    this.subscriptions.push(
      this.settingsService.getCompanySettings().subscribe((settings: CompanySettingsVM) => {
        if (!settings) {
          return;
        }

        this.settings = settings;
        this.form.patchValue(settings);

        this.loading = false;
      }),
    );
  }
}
