import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { AppState } from "src/app/shared/state/app.state";
import { Setting } from "../../shared/models/setting.model";
import { fromEvent, Subscription } from "rxjs";
import { UIState } from "../../shared/state/ui.state";
import { AuthService } from "../../auth/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { SettingsDialogComponent } from "../../admin/settings/settings-dialog/settings-dialog.component";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";

export class SettingGroup {
  name: string;
  expanded: boolean;
  settings: Setting[];

  dataSource: MatTableDataSource<Setting>;
}

@Component({
  selector: "app-company-details",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  filterText: string = "";

  loading: boolean = false;

  @ViewChild("filterInput")
  filterInput: ElementRef;

  settings: Setting[] = [];
  settingGroups: SettingGroup[] = [];

  filteredSettingGroups: SettingGroup[] = [];

  displayedColumns = ["Key", "Description", "Value", "Comment", "Actions"];

  ignoredGroups: string[] = ["Administratieve Eenheid"];

  constructor(
    public appState: AppState,
    public authService: AuthService,
    public uiState: UIState,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.subscriptions.push(
      fromEvent(this.filterInput.nativeElement, "keyup")
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe(() => {
          this.applyFilter();
        }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.hasSuperadmin().subscribe((res: boolean) => {
        if (!res) {
          this.router.navigateByUrl("/");
          return;
        }

        this.uiState.breadcrumbs$.next([
          {
            name: "Instellingen",
          },
        ]);

        this.loading = true;
        this.subscriptions.push(
          this.appState.settings$.subscribe((settings: Setting[]) => {
            if (!settings) {
              return;
            }

            this.settings = settings;
            this.buildSettingGroups(settings);
            this.applyFilter();
            this.loading = false;
          }),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openSettingsDialog(setting: Setting): void {
    this.matDialog.open(SettingsDialogComponent, {
      width: "780px",
      data: setting,
      disableClose: true,
    });
  }

  buildSettingGroups(settings: Setting[]): void {
    for (let setting of settings) {
      if (this.ignoredGroups.includes(setting.Group)) {
        continue;
      }

      let existingGroup = this.settingGroups.filter((x) => x.name === setting.Group);
      if (existingGroup.length === 1) {
        let existingSetting = existingGroup[0].settings.filter((x) => x.Key === setting.Key);
        if (existingSetting.length === 1) {
          existingSetting[0].Value = setting.Value;
          continue;
        }

        existingGroup[0].settings.push(setting);
        continue;
      }

      this.settingGroups.push({
        name: setting.Group,
        expanded: false,
        settings: [setting],
        dataSource: new MatTableDataSource(),
      });
    }

    // build dataSources once to prevent rerendering tables
    for (const group of this.settingGroups) {
      group.dataSource.data = this.sortSettings(group.settings, "Description", true);
    }

    this.settingGroups = this.settingGroups.sort((a, b) => this.compareString(a.name, b.name));
  }

  applyFilter(): void {
    if (!this.filterText || this.filterText === "" || this.filterText.trim() === "") {
      this.filteredSettingGroups = this.settingGroups;
      return;
    }

    let filteredGroups: SettingGroup[] = [];
    const filterText = this.filterText.trim().toLowerCase();

    for (const settingGroup of this.settingGroups) {
      const filteredSettings = settingGroup.settings.filter(
        (x) =>
          x.Key.toLowerCase().includes(filterText) ||
          x.Description.toLowerCase().includes(filterText) ||
          x.Comment.toLowerCase().includes(filterText) ||
          x.Value.toLowerCase().includes(filterText)
      );

      if (filteredSettings.length === 0) {
        continue;
      }

      filteredGroups.push({
        name: settingGroup.name,
        expanded: true,
        settings: filteredSettings,
        dataSource: new MatTableDataSource(),
      });
    }

    // build dataSources once to prevent rerendering tables
    for (const group of filteredGroups) {
      group.dataSource.data = this.sortSettings(group.settings, "Description", true);
    }

    this.filteredSettingGroups = filteredGroups.sort((a, b) => this.compareString(a.name, b.name));
  }

  removeFilter(): void {
    this.filterText = "";
    this.applyFilter();
  }

  onSortData(sort: MatSort, group: SettingGroup) {
    if (sort.active && sort.direction !== "") {
      group.dataSource.data = this.sortSettings(group.dataSource.data, sort.active, sort.direction === "asc");
    }
  }

  sortSettings(settings: Setting[], field: string, isAscending: boolean): Setting[] {
    if (!field) {
      return settings;
    }

    return settings.sort((a: Setting, b: Setting) => {
      const order = isAscending ? 1 : -1;

      switch (field) {
        case "Key":
          return this.compareString(a.Key, b.Key) * order;
        case "Description":
          return this.compareString(a.Description, b.Description) * order;
        case "Value":
          return this.compareString(a.Value, b.Value) * order;
        case "Type":
          return this.compareString(a.Type, b.Type) * order;
        case "Comment":
          return this.compareString(a.Comment, b.Comment) * order;
        default:
          return 0;
      }
    });
  }

  private compareString(a: string, b: string): number {
    return a?.toLowerCase() > b?.toLowerCase() ? 1 : -1;
  }
}
