import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { Logger } from "./shared/helpers/logger";
import { ServiceWorkerUpdateService } from "./shared/services/service-worker-update.service";
import { AppState } from "./shared/state/app.state";

@Component({
  selector: "app-root",
  templateUrl: "./app.container.html",
  styleUrls: ["./app.container.scss"],
})
export class AppContainerComponent implements OnInit {
  constructor(
    private logger: Logger,
    private appState: AppState,
    private serviceWorkerUpdateService: ServiceWorkerUpdateService,
    private authService: AuthService,
  ) {
    serviceWorkerUpdateService.initialize();

    // console.log(" !!!! AppContainerComponent");
    // this.logger.ais_logPageView("application start");
    // this.logger.info("AppContainerComponent => constructor ");
  }

  ngOnInit(): void {}
}
