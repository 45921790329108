import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Setting } from "src/app/shared/models/setting.model";
import { MessageService } from "src/app/shared/services/message.service";
import { SettingsService } from "src/app/shared/services/settings.service";
import { AppState } from "src/app/shared/state/app.state";

@Component({
  selector: "app-settings-dialog",
  templateUrl: "./settings-dialog.component.html",
  styleUrls: ["./settings-dialog.component.scss"],
})
export class SettingsDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  saving: boolean;
  setting: Setting;

  form: FormGroup = this.fb.group({
    Id: [{ value: null, disabled: true }],
    Key: [{ value: null, disabled: true }],
    Value: [{ value: null, disabled: false }],
    Type: [{ value: null, disabled: true }],
    Description: [{ value: null, disabled: true }],
    Comment: [{ value: null, disabled: true }],
  });

  constructor(
    public matDialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private messageService: MessageService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.setting = this.data as Setting;
    this.form.patchValue(this.setting);

    if (this.setting.Type === "boolean") {
      this.form.get("Value").setValue(Boolean(JSON.parse(this.setting.Value || "0")));
    }

    this.subscriptions.push(
      this.matDialogRef.backdropClick().subscribe(() => {
        this.close();
      }),
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  save(): void {
    if (this.saving || this.form.invalid) {
      return;
    }

    this.saving = true;
    this.setting = { ...this.setting, ...this.form.getRawValue() };

    if (this.setting.Type === "boolean") {
      this.setting.Value = this.form.get("Value").value ? "true" : "false";
    }

    this.subscriptions.push(
      this.settingsService.update(this.setting).subscribe((setting: Setting) => {
        this.saving = false;

        this.setting = setting;
        this.form.patchValue(this.setting);
        this.form.markAsPristine();

        this.close();
      }),
    );
  }

  close(): void {
    this.subscriptions.push(
      this.messageService
        .showUnsavedChanges({
          hasChanges: this.form.dirty,
          text: "Bent u zeker dat u deze pop-up wilt sluiten?",
          yesLabel: "SLUITEN ZONDER OPSLAAN",
        })
        .subscribe((value: boolean) => {
          if (value) {
            this.matDialogRef.close();
            this.subscriptions.push(this.settingsService.get().subscribe());
          }
        }),
    );
  }
}
