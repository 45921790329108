import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Breadcrumb } from "../shared/models/app.models";
import { AuthService } from "../auth/auth.service";
import { AppState } from "../shared/state/app.state";
import { UIState } from "../shared/state/ui.state";
import { OfficialService } from "../shared/services/official.service";
import { ActivatedRoute } from "@angular/router";
import { SettingsService } from "../shared/services/settings.service";
import { StorageService } from "../shared/services/storage.service";
import { NotificationDialogComponent } from "../shared/components/notification-dialog/notification-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Community } from "../shared/models/community.model";
import { Logger } from "../shared/helpers/logger";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {
  private subscriptions: Subscription[] = [];

  public environment: any;

  breadcrumbs: Breadcrumb[] = [];
  nxCode: string;
  communityName: string;
  moduleVLOK = false;
  moduleCadastral = false;
  moduleVermoedensLijst = false;
  moduleLoket = false;
  moduleEid = false;
  rootFolderLink: string;
  rootFolderLinkLoket: string;

  footerText: string;

  navColor = "#fff";
  toolbarColor = "rgba(77, 206, 226, 0.33)";

  constructor(
    public appState: AppState,
    public uiState: UIState,
    private officialService: OfficialService,
    public settingService: SettingsService,
    public authService: AuthService,
    public storageService: StorageService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private logger: Logger,
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((queryParams) => {
        const backToQueryParam = queryParams.get("terug-naar");
        if (backToQueryParam) {
          const breadcrumbs: Breadcrumb[] = [];
          const breadcrumbsArr = backToQueryParam.split(",");
          if (breadcrumbsArr?.length > 0) {
            for (let breadcrumbAsString of breadcrumbsArr) {
              const breadcrumbArr = breadcrumbAsString.split(":");
              if (breadcrumbArr?.length > 0) {
                const breadcrumb: Breadcrumb = new Breadcrumb();
                breadcrumb.name = breadcrumbArr[0];
                if (breadcrumbArr[1]) {
                  breadcrumb.routerLink = breadcrumbArr[1];
                }

                breadcrumbs.push(breadcrumb);
              }
            }
          }

          this.breadcrumbs = breadcrumbs;
          return;
        }

        this.subscriptions.push(
          this.uiState.breadcrumbs$.subscribe((breadcrumbs) => {
            this.breadcrumbs = breadcrumbs;
          }),
        );
        this.subscriptions.push(
          this.settingService.getRootFolder().subscribe((res) => {
            this.rootFolderLink = res;
          }),
        );
        this.subscriptions.push(
          this.settingService.getRootFolderLoket().subscribe((res) => {
            this.rootFolderLinkLoket = res;
          }),
        );
      }),
    );

    // ! this might have to be removed once there are settings for non-community
    if (this.appState.activeCommunity$.value.IsCommunity) {
      this.logger.info(
        "HomeComponent - ngOnInit : this.appState.availableCommunities$.value",
        this.appState.availableCommunities$.value,
      );
      this.subscriptions.push(this.officialService.getList().subscribe());
      this.subscriptions.push(
        this.settingService.get().subscribe((result) => {
          this.moduleCadastral = Boolean(JSON.parse(this.settingService.getSettingByKey("Adm_Config_Kadaster").Value || "0"));
          this.moduleVermoedensLijst = Boolean(
            JSON.parse(this.settingService.getSettingByKey("module-vermoedenslijst")?.Value || "0"),
          );
          this.moduleLoket = Boolean(JSON.parse(this.settingService.getSettingByKey("module-loket")?.Value || "0"));
          this.moduleEid = Boolean(JSON.parse(this.settingService.getSettingByKey("module-eid")?.Value || "0"));
          this.moduleVLOK = false;
        }),
      );
      this.subscriptions.push(this.settingService.getGeb().subscribe());
    }

    this.footerText = this.settingService.getSettingByKey("footer-text")?.Value;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  logout(): void {
    this.authService.logout(false);
  }

  changeCommunity(nxCode: Community): void {
    this.logger.info("HomeComponent - changeCommunity: ", this.appState.allowedToChangeCommunity);
    // addition check where it is allowed to change, dont comment this code, keep flow logic consistent
    if (!this.appState.allowedToChangeCommunity) {
      this.matDialog.open(NotificationDialogComponent, {
        width: "550px",
        data: {
          title: `Gemeente kan niet gewisseld worden.`,
          text: `Er zijn nog niet-opgeslagen wijzigingen.`,
        },
      });
      return;
    }

    if (this.appState.activeCommunity$.value.NxCode !== nxCode.NxCode) {
      // ! put empty settings to prevent cached settings from applying to other communities
      this.appState.settings$.next([]);
    }

    this.appState.activeCommunity$.next(nxCode);
  }

  navigateRootDrive() {
    if (this.rootFolderLink) {
      window.open(this.rootFolderLink, "_blank");
      this.uiState.toggleSideNav();
    }
  }

  navigateRootDriveLoket() {
    if (this.rootFolderLink) {
      window.open(this.rootFolderLinkLoket, "_blank");
      this.uiState.toggleSideNav();
    }
  }
}
