import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Community } from "src/app/shared/models/community.model";
import { Official } from "src/app/shared/models/official.model";

@Component({
  selector: "app-add-user-dialog",
  templateUrl: "./add-community-dialog.component.html",
  styleUrls: ["./add-community-dialog.component.scss"],
})
export class AddCommunityDialogComponent {
  saving: boolean;

  form: FormGroup = this.fb.group({
    Niscode: [null, Validators.required],
    OfficialCode: [null, Validators.required],
  });

  communities: Community[];
  isIgsSelected: boolean;

  officials: Official[];
  officialsByNiscode?: Map<string, Official[]>;

  constructor(
    public matDialogRef: MatDialogRef<AddCommunityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
  ) {
    this.communities = data.communities;
    this.officialsByNiscode = data.officials;
  }

  close(result: any): void {
    this.matDialogRef.close(result);
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.close(this.form.getRawValue());
  }

  onCommunityChanged(): void {
    const value = this.form.get("Niscode").value;
    if (!value) {
      return;
    }

    const community = this.communities.filter((x) => x.NxCode === value)[0];
    this.isIgsSelected = !community.IsCommunity;
    if (this.isIgsSelected) {
      this.form.get("OfficialCode").setValue("1");
    }

    this.officials = this.officialsByNiscode.get(value);
  }
}
