import { locale } from 'moment';
import { AppContainerComponent } from "./app.container";
import { AppRoutingModule } from "./app-routing.module";
import { AuthLoginComponent } from "./auth/login/login.component";
import { AuthSSOCallbackComponent } from "./auth/sso-callback/sso-callback.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, SecurityContext } from "@angular/core";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HomeComponent } from "./home/home.component";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { CompanyDetailsComponent } from "./settings/company-details/company-details.component";
import { SharedModule } from "./shared/shared.module";
import { oauthModuleConfig } from "./auth/auth.config";
import { environment } from "src/environments/environment";
import { ExportFinancialServiceComponent } from "./export-financial-service/export-financial-service.component";
import { OwnersComponent } from "./owners/owners.component";
import { SettingsDialogComponent } from "./admin/settings/settings-dialog/settings-dialog.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MarkdownModule } from "ngx-markdown";
import { DocumentationComponent } from "./documentation/documentation.component";
import { OpenDossierComponent } from "./open/open.component";
import { LettersManagementComponent } from './settings/letters-management/letters-management.component';
import { SettingsComponent } from "./admin/settings/settings.component";
import { ExportOverviewComponent } from "./export-overview/export-overview.component";
import { SuspectsComponent } from "./suspects/suspects.component";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DownloadCenterComponent } from './download-center/download-center.component'
import { VlokImportComponent } from "./settings/vlok-import/vlok-import.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { LetterParametersComponent } from './settings/letter-parameters/letter-parameters.component';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from "angular-calendar";
import { registerLocaleData } from "@angular/common";
import localeBE from '@angular/common/locales/nl-BE';
import { AddCommunityDialogComponent } from './settings/user-management/add-community-dialog/add-community-dialog.component';
import { AddUserDialogComponent } from './settings/user-management/add-user-dialog/add-user-dialog.component';
import { UserManagementComponent } from './settings/user-management/user-management.component';
import { AddGroupDialogComponent } from './settings/user-management/add-group-dialog/add-group-dialog.component';
import { AdGroupsComponent } from './admin/ad-groups/ad-groups.component';
import { AddAdGroupDialogComponent } from './admin/ad-groups/add-ad-group-dialog/add-ad-group-dialog.component';

class CustomDateFormatter extends CalendarNativeDateFormatter {
  public dayViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }
}

registerLocaleData(localeBE);
@NgModule({
  declarations: [
    AppContainerComponent,
    HomeComponent,
    AuthLoginComponent,
    AuthSSOCallbackComponent,
    CompanyDetailsComponent,
    ExportFinancialServiceComponent,
    OwnersComponent,
    SettingsDialogComponent,
    DocumentationComponent,
    OpenDossierComponent,
    UserManagementComponent,
    LettersManagementComponent,
    SettingsComponent,
    SuspectsComponent,
    ExportOverviewComponent,
    DownloadCenterComponent,
    VlokImportComponent,
    AddUserDialogComponent,
    AddCommunityDialogComponent,
    AddGroupDialogComponent,
    LetterParametersComponent,
    AdGroupsComponent,
    AddAdGroupDialogComponent
  ],
  imports: [
    NgxMaskModule.forRoot({
      prefix: ""
    }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    ClipboardModule,
    NgxMaterialTimepickerModule,
    NgxFileDropModule,
    // Auth
    OAuthModule.forRoot(oauthModuleConfig),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 10 seconds (whichever comes first).
      registrationStrategy: "registerImmediately",
    }),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    // Auth
    { provide: OAuthStorage, useValue: localStorage },
    {provide: CalendarDateFormatter, useClass: CustomDateFormatter}
  ],
  bootstrap: [AppContainerComponent],
})
export class AppModule {
  constructor() {}
}
