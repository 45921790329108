import { Role } from "./user.model";

export class LoginCredentials {
  Email: string;
  Password: string;
}

export class NXUser {
  Id: string;
  Email: string;
  Username: string;
  Name: string;
  ActiveOfficialCode: string;
  OfficialCodes: string[] = [];
  Roles: Role[] = [];
  Permissions: string[] = [];
  NisCodes: string[] = [];

  //For Troubleshooting
  DateNBF: Date; // not before
  DateIAT: Date; // Issued at
  DateEXP: Date; // Experation
  DateAuthTime: Date; //
  // MultiCommunity: string;  => this is not for userlevel
  // IsMultiCommunity: boolean = false; => this is not for userlevel
}
