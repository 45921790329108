import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { AdGroupModel, OperationType, PermissionManageModel, Role, UserManageModel } from "src/app/shared/models/user.model";
import { MessageService } from "src/app/shared/services/message.service";
import { AppState } from "src/app/shared/state/app.state";
import { UIState } from "src/app/shared/state/ui.state";
import { AddUserDialogComponent } from "./add-user-dialog/add-user-dialog.component";
import { filter, take, tap, switchMap } from "rxjs/operators";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Official } from "src/app/shared/models/official.model";
import { AddCommunityDialogComponent } from "./add-community-dialog/add-community-dialog.component";
import { forkJoin } from 'rxjs';
import { OfficialService } from "src/app/shared/services/official.service";
import { UserService } from "src/app/shared/services/user.service";
import { SpinnerDialogComponent } from "src/app/shared/components/spinner-dialog/spinner-dialog.component";
import { AddGroupDialogComponent } from "./add-group-dialog/add-group-dialog.component";

@Component({
  selector: "app-beheersrechten",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  filterText: string = "";
  roles: Role[];
  groups: AdGroupModel[];
  officialsByNiscode: Map<string, Official[]> = new Map();

  loading: boolean = false;

  usersTableColumns: string[] = ["Gebruiker"];
  usersDataSource = new MatTableDataSource<UserManageModel>();
  users: UserManageModel[] = [];

  permissionTableColumns: string[] = ["Niscode", "Ambtenaarnaam", "Actions"];
  groupTableColumns: string[] = ["Naam", "Actions"];

  selectedUserPermissionsDataSource = new MatTableDataSource<PermissionManageModel>();
  selectedUserGroupsDataSource = new MatTableDataSource<AdGroupModel>();
  selectedUser?: UserManageModel;

  selectedPermission?: PermissionManageModel;
  officials?: Official[];

  constructor(
    private appState: AppState,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private uiState: UIState,
    private dialogService: MatDialog,
    private officialService: OfficialService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.authService
      .hasScreenResource("GEBRUIKERSBEHEER", OperationType.UPDATE)
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            this.router.navigateByUrl("/");
            return;
          }

          this.getData();

          this.uiState.breadcrumbs$.next([
            {
              name: "Gebruikersbeheer",
            },
          ]);
        }),
      )
      .subscribe();
  }

  applyFilter(): void {
    this.usersDataSource.filter = this.filterText.trim().toLowerCase();
  }

  removeFilter(): void {
    this.filterText = "";
    this.applyFilter();
  }

  toggleUserSelection(user: UserManageModel): void {
    if (this.selectedUser === user) {
      this.selectedUser = null;
      this.selectedUserPermissionsDataSource = new MatTableDataSource();
      this.selectedUserGroupsDataSource = new MatTableDataSource();
    } else {
      this.selectedUser = user;
      this.selectedUserPermissionsDataSource = new MatTableDataSource(
        this.selectedUser.Permissions.sort((a, b) => (!this.isCommunityIgs(a.Niscode) ? -1 : +a.Niscode - +b.Niscode)),
      );

      this.selectedUserGroupsDataSource = new MatTableDataSource(
        this.selectedUser.Groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
      );

      this.togglePermissionSelection(null);
    }
  }

  togglePermissionSelection(permission: PermissionManageModel): void {
    if (permission !== null && !this.isCommunityIgs(permission.Niscode)) {
      return;
    }

    if (this.selectedPermission === permission) {
      this.selectedPermission = null;
    } else {
      this.selectedPermission = permission;

      if (permission !== null) {
        this.officials = this.officialsByNiscode.get(permission.Niscode);
      }
    }
  }

  getCommunityName(niscode: string): string {
    const communities = this.appState.communities$.value.filter((x) => x.NxCode === niscode);
    if (communities.length <= 0) {
      return "Onbekend";
    }

    return communities[0].Name;
  }

  getOfficialName(permission: PermissionManageModel): string {
    if (permission.OfficialCode === "1") {
      return "IGS";
    }

    if (!this.officialsByNiscode.has(permission.Niscode)) {
      return "Niscode onbekend";
    }

    const officials = this.officialsByNiscode.get(permission.Niscode).filter((x) => x.Code === permission.OfficialCode);
    if (officials.length <= 0) {
      return "Naam onbekend";
    }

    return officials[0].Name;
  }

  isCommunityIgs(niscode: string): boolean {
    const communities = this.appState.communities$.value.filter((x) => x.NxCode === niscode);
    if (communities.length <= 0) {
      return true;
    }

    return communities[0].IsCommunity;
  }

  hasRole(roleId: number): boolean {
    if (!this.selectedPermission) {
      return;
    }

    return this.selectedPermission.Roles.includes(roleId);
  }

  setRole(roleId: number, event: MatCheckboxChange): void {
    if (!this.selectedPermission) {
      return;
    }

    if (event.checked) {
      this.selectedPermission.Roles.push(roleId);
    } else {
      this.selectedPermission.Roles = this.selectedPermission.Roles.filter((x) => x !== roleId);
    }
  }

  reset(): void {
    this.users = [];
    this.usersDataSource = new MatTableDataSource();

    this.selectedUser = null;
    this.selectedPermission = null;
    this.officials = null;

    this.selectedUserPermissionsDataSource = new MatTableDataSource();
    this.selectedUserGroupsDataSource = new MatTableDataSource();
  }

  getData(): void {
    this.loading = true;
  
    this.appState.communities$.pipe(
      filter((communities) => communities?.length !== 0),
      take(1),
      switchMap((communities) => {
        const officialsRequests = communities
          .filter(community => community.IsCommunity)
          .map(community => this.officialService.getOfficialsForCommunity(community.NxCode).pipe(
            take(1),
            tap((officials) => {
              this.officialsByNiscode.set(community.NxCode, officials);
            })
          ));

        const userRequest = this.userService.getUsersWithDetails().pipe(
          take(1),
          tap((users) => {
            this.users = users;
            this.usersDataSource = new MatTableDataSource(users);
          }
        ));

        const roleRequest = this.userService.getRoles().pipe(
          take(1),
          tap((roles) => {
            this.roles = roles;
          }
        ));

        const groupRequest = this.userService.getGroups().pipe(
          take(1),
          tap((groups) => {
            this.groups = groups;
          }
        ));
  
        return forkJoin([userRequest, roleRequest, groupRequest, forkJoin(officialsRequests)]);
      })
    ).subscribe({
      next: () => {
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
        this.loading = false;
      }
    });
  }
  
  getUsers(): void {
    this.userService.getUsersWithDetails()
      .pipe(
        take(1),
        tap((r) => {
          this.users = r;
          this.usersDataSource = new MatTableDataSource(r);
        }),
      )
      .subscribe();
  }

  save(): void {
    const ref = this.dialogService.open(SpinnerDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {
        title: "Bezig met bijwerken van gebruikers..."
    }});

    this.userService
      .updateUsers(this.usersDataSource.data)
      .pipe(
        take(1),
        tap((r) => {
          ref.close();

          if (!r.Success) {
            this.messageService.showSnackBarError(r.Message);
          } else {
            this.messageService.showSnackBarInfo("Gebruikers bijgewerkt");

            this.reset();
            this.getUsers();
          }
        }),
      )
      .subscribe();
  }

  createNewUser(): void {
    const ref = this.dialogService.open(AddUserDialogComponent, {
      width: "580px",
    });

    ref.afterClosed()
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.getUsers();
        }),
      )
      .subscribe();
  }

  addNewGroup(): void {
    if (!this.selectedUser) {
      return;
    }

    const ref = this.dialogService.open(AddGroupDialogComponent, {
      width: "580px",
      data: {
        groups: this.groups.filter((y) => this.selectedUser.Groups.filter((x) => x.AdId === y.AdId).length === 0),
      },
    });

    ref.afterClosed()
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.selectedUser.Groups.push({
            AdId: r.AdId,
            Name: r.Name
          });

          this.selectedUserGroupsDataSource = new MatTableDataSource(
            this.selectedUser.Groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
          );
        }),
      )
      .subscribe();
  }

  addNewCommunity(): void {
    if (!this.selectedUser) {
      return;
    }

    const ref = this.dialogService.open(AddCommunityDialogComponent, {
      width: "580px",
      data: {
        officials: this.officialsByNiscode,
        communities: this.appState.communities$.value.filter(
          (y) => this.selectedUser.Permissions.filter((x) => x.Niscode === y.NxCode).length === 0,
        ),
      },
    });

    ref.afterClosed()
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.selectedUser.Permissions.push({
            Niscode: r.Niscode,
            OfficialCode: r.OfficialCode,
            Roles: [],
          });

          this.selectedUserPermissionsDataSource = new MatTableDataSource(
            this.selectedUser.Permissions.sort((a, b) => (!this.isCommunityIgs(a.Niscode) ? -1 : +a.Niscode - +b.Niscode)),
          );

          this.togglePermissionSelection(this.selectedUser.Permissions.filter((x) => x.Niscode === r.Niscode)[0]);
        }),
      )
      .subscribe();
  }

  onDeleteGroupClicked(event: Event, group: AdGroupModel): void {
    event.stopPropagation();

    this.messageService.openConfirmationDialog(
        "Verwijderen uit groep",
        `Weet u zeker dat u de toegang voor gebruiker '${this.selectedUser.Name}' op groep '${group.Name}' wilt verwijderen? Dit zal ervoor zorgen dat de gebruiker ook niet meer op Sharepoint sites die gekoppeld zijn aan deze groep zal geraken.`,
      )
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.togglePermissionSelection(null);

          this.selectedUser.Groups = this.selectedUser.Groups.filter((x) => x.AdId !== group.AdId);
          this.selectedUserGroupsDataSource = new MatTableDataSource(
            this.selectedUser.Groups.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1))
          );
        }),
      )
      .subscribe();
  }

  onDeleteClicked(event: Event, permission: PermissionManageModel): void {
    event.stopPropagation();

    this.messageService.openConfirmationDialog(
        "Verwijderen toegang op bestuur",
        `Weet u zeker dat u de toegang voor gebruiker '${this.selectedUser.Name}' op bestuur '${permission.Niscode}' wilt verwijderen?`,
      )
      .pipe(
        take(1),
        tap((r) => {
          if (!r) {
            return;
          }

          this.togglePermissionSelection(null);

          this.selectedUser.Permissions = this.selectedUser.Permissions.filter((x) => x.Niscode !== permission.Niscode);
          this.selectedUserPermissionsDataSource = new MatTableDataSource(
            this.selectedUser.Permissions.sort((a, b) => (!this.isCommunityIgs(a.Niscode) ? -1 : +a.Niscode - +b.Niscode)),
          );
        }),
      )
      .subscribe();
  }
}
