import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { MessageService } from "../services/message.service";
import { from, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { AuthService } from "src/app/auth/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(private messageService: MessageService, private router: Router, private injector: Injector) {
    // ! workaround to fix dependency injection failing as AuthService is configured _after_ this
    setTimeout(() => {
      this.authService = this.injector.get(AuthService);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        (res: HttpErrorResponse): Observable<any> => {
          if (res.status === 0) {
            // ! navigate to prevent being stuck on a white sso-redirect page
            from(this.router.navigate(["/login"])).subscribe(() => this.messageService.showSnackBarError("Geen verbinding. We kunnen de server niet bereiken."));
          } else if (res.status === 400) {
            if (res?.error?.error === "invalid_grant") {
              this.router.navigateByUrl("/login");
            } else if (res?.error?.Message || res?.error?.error) {
              this.messageService.showSnackBarError(res?.error?.Message || res?.error.error);
            } else if (res?.error) {
              let errorMessage = "Er ging iets mis. Gelieve contact op te nemen met support.";
              errorMessage += " ERROR: " + JSON.stringify(res?.error);
              this.messageService.showSnackBarError(errorMessage);
            }
          } else if (res.status === 401 || res.status === 403 || res.status == 418) {
            if (res.status === 403) {
              this.messageService.showSnackBarError("Toegang geweigerd.");
            }

            if (res.status == 418) {
              this.authService.logout(true);

              from(this.router.navigate(["/login"])).subscribe(() => {                
                this.messageService.showSnackBarWarning("Uw account is nog niet geactiveerd, gelieve contact op te nemen met support", { duration: 0 });
              });
              
              return;
            }

            this.router.navigateByUrl("/login");
          } else {
            let errorMessage = "Er ging iets mis. Gelieve contact op te nemen met support.";
            if (res.error && res.error.Message) {
              errorMessage += " ERROR: " + res.error.Message;
            }
            this.messageService.showSnackBarError(errorMessage);
          }

          throw res;
        },
      ),
    );
  }
}
