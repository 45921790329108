import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { ExportOverviewService } from "./export-overview.service";
import { Response } from "../shared/models/response.model";
import { SettingExportView } from "../shared/models/setting-export-view.model";
import { UIState } from "../shared/state/ui.state";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Dossier } from "../shared/models/dossier.model";

@Component({
  selector: "app-export-overview",
  templateUrl: "./export-overview.component.html",
  styleUrls: ["./export-overview.component.scss"],
})
export class ExportOverviewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  views: SettingExportView[] = [];
  selectedView: SettingExportView;

  filterText: string;

  filter: number;
  loading = true;
  displayedColumns: string[] = ["Name", "Actions"];
  dataSource: MatTableDataSource<SettingExportView> = new MatTableDataSource<SettingExportView>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public exportOverviewService: ExportOverviewService, private uiState: UIState) {}

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.uiState.breadcrumbs$.next([
      {
        name: "Overzichten",
      },
    ]);

    this.getList();
  }

  getList(): void {
    this.loading = true;
    this.subscriptions.push(this.exportOverviewService.getViews().subscribe((response: Response) => {
      this.views = response.Data;
      this.dataSource = new MatTableDataSource<SettingExportView>(this.views);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, (_) => {
      this.loading = false;
    }));
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterText.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  removeFilter(): void {
    this.filterText = "";
    this.applyFilter();
  }

}
