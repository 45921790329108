<div class="dialog">
  <button class="dialog-close" type="button" mat-mini-fab color="primary" (click)="close(null)">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="mat-headline">
          <span>Bestuur toevoegen</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button type="submit" [disabled]="saving" mat-raised-button color="primary">Opslaan</button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field fxFlex="100%">
          <mat-label>Bestuur *</mat-label>
          <mat-select formControlName="Niscode" (selectionChange)="onCommunityChanged()">
            <mat-option *ngFor="let option of communities" [value]="option.NxCode">{{ option.Name }} ({{ option.NxCode }})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <ng-container *ngIf="!isIgsSelected">
          <mat-form-field fxFlex="100%">
            <mat-label>Ambtenaar</mat-label>
            <mat-select formControlName="OfficialCode">
              <mat-option *ngFor="let option of officials" [value]="option.Code">{{ option.Name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="isIgsSelected">
          <span>Op IGS-niveau moet er geen ambtenaar of rollen ingesteld worden.</span>
        </ng-container>
      </div>
    </div>
  </form>
</div>
