import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../state/app.state';
import { FileStorageFile } from '../models/file-storage-file.model';

@Injectable({
  providedIn: 'root'
})
export class ImportVlokService {
  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient, private appState: AppState) {} 

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  importCsv(file: FormData): Observable<Blob> {
    const options = {
      responseType: 'blob' as 'json', // Explicitly set responseType to 'blob'
    };
    return this.http.post<Blob>(`${this.appState.baseApiUrl}/import/vlok` , file, options );
  }
}
