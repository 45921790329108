<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-title">Groepen</div>
    <div fxLayoutGap="24px">
      <button mat-raised-button color="primary" (click)="addNewGroup()">Nieuw</button>
      <button mat-raised-button color="primary" (click)="save()">Opslaan</button>
    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="start start" class="page-content" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="16px" fxFill>
    <div class="table-responsive">
      <table
        fxFlexFill
        mat-table
        [dataSource]="groupsDataSource"
      >
        <ng-container matColumnDef="Name">
          <th class="w-100" mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let group">{{ group.Name }}</td>
        </ng-container>

        <ng-container matColumnDef="Id">
          <th class="w-100" mat-header-cell *matHeaderCellDef>AD Object ID</th>
          <td mat-cell *matCellDef="let group">{{ group.AdId }}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let item">
            <div fxLayout="row" fxLayoutAlign="end center">
              <button mat-icon-button matTooltip="Verwijderen" (click)="removeGroup(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [style.border-bottom]="'1.5px solid #4f606f'"
          class="clickable"
          mat-row
          *matRowDef="let group; columns: displayedColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">Geen groepen gevonden.</td>
        </tr>
      </table>
    </div>
  </div>
</div>