import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Logger } from "../shared/helpers/logger";
import { MessageService } from "../shared/services/message.service";
import { StorageService } from "../shared/services/storage.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated().pipe(map(isAuthenticated => {
      if (isAuthenticated) {
        return true;
      }


      this.router.navigateByUrl("/login");
      return false;
    }));
  }
}

@Injectable({
  providedIn: "root",
})
export class IsModuleInstalledGuard implements CanActivate {
  constructor(private authService: AuthService) { }

  canActivate(childRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkModule(childRoute);
  }

  private checkModule(route: ActivatedRouteSnapshot):boolean {
    let hasModuleInstalled = false;
    const checkRouteParam = route.queryParamMap.get('module');
    const checkModule = this.authService.hasModule(checkRouteParam);

    if (checkModule) {
        hasModuleInstalled = true;
    }
    else {
        hasModuleInstalled = false;
    }

    return hasModuleInstalled;
  }
}

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private logger: Logger,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // ! check if user has permission
    return this.checkPermission(route).pipe(map(hasPermission => {
      if (hasPermission) {
        return true;
      }
      
      this.authService.isAuthenticated().pipe(map(isAuthenticated => {
        // ! if user has no permission, but they are logged in, they don't have access to the community
        if (isAuthenticated) {
          this.messageService.showSnackBarError("Toegang geweigerd. U heeft onvoldoende rechten.");
        }
  
        this.router.navigateByUrl("/login");
        
        return false;
      }));
    }));
  }

  private checkPermission(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!route.data.permissions) {
      return of(true);
    }

    return this.authService.hasPermission(route.data.permissions[0]).pipe(map(x => {
      return x;  
    }));
  }
}
