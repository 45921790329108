import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { interval, Observable, of, Subject, Subscription } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { NotificationDialogComponent } from "../components/notification-dialog/notification-dialog.component";
import { OnDeactivatePayload } from "../guards/can-deactivate.guard";
import { AppState } from "../state/app.state";

export class MessageQueueItem{
  message: string;
  action?: string;
  config?: MatSnackBarConfig;
  priority: number;
  indefinate: boolean; //indefinate messages will have lowest priority and are always displayed at the end

  constructor(priority: number, message: string, action?: string, config?: MatSnackBarConfig, indefinate: boolean = false){
    this.message = message;
    this.action = action;
    this.config = config;
    this.priority = priority;
    this.indefinate = indefinate
  }
}
@Injectable({ providedIn: "root" })
export class MessageService {

  private queue:  MessageQueueItem[];
  private indefinateItem: MessageQueueItem;
  currentMessage: MessageQueueItem;
  snackbarRef: MatSnackBarRef<TextOnlySnackBar>;
  snackbarSubscription: Subscription;

  constructor(private snackBar: MatSnackBar, private matDialog: MatDialog, private appState: AppState) {
    this.queue = [];
  }

  showUnsavedChanges(payload: OnDeactivatePayload): Observable<boolean> {
    if (payload.hasChanges) {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: "550px",
        disableClose: true,
        data: {
          title: payload.title || "Niet-opgeslagen wijzigingen",
          text: payload.text || "Bent u zeker dat u deze pagina wilt verlaten?",
          yesLabel: payload.yesLabel || "VERLATEN ZONDER OPSLAAN",
          noLabel: payload.noLabel || "ANNULEREN",
        },
      });

      return dialogRef.afterClosed().pipe(
        switchMap((yesOrNo: boolean) => {
          if (yesOrNo) {
            this.appState.allowedToChangeCommunity = true;

            if (payload.onYes) {
              payload.onYes();
            }
            return of(true);
          } else {
            if (payload.onNo) {
              payload.onNo();
            }
            return of(payload.navigateOnNo);
          }
        }),
      );
    }

    return of(true);
  }

  showSnackBarInfo(message: string, configOverride?: MatSnackBarConfig, indefinate?: boolean) {
    let config = new MatSnackBarConfig();
    config.horizontalPosition = "left";
    config.panelClass = ["snackbar-custom-info-class"];
    config.duration = 2000;
    if (configOverride) {
      config = { ...config, ...configOverride };
    }

    this.addMessage(2, message, null, config, indefinate);
    return null;
  }

  showSnackBarWarning(message: string, configOverride?: MatSnackBarConfig, indefinate?: boolean) {
    let config = new MatSnackBarConfig();
    config.horizontalPosition = "left";
    config.panelClass = ["snackbar-custom-warning-class"];
    config.duration = 10000;
    if (configOverride) {
      config = { ...config, ...configOverride };
    }

    this.addMessage(1, message, null, config, indefinate);
    return null;
  }

  showSnackBarError(message: string, configOverride?: MatSnackBarConfig, indefinate: boolean = false) {
    let config = new MatSnackBarConfig();
    config.horizontalPosition = "left";
    config.panelClass = ["snackbar-custom-error-class"];
    config.duration = 10000;
    if (configOverride) {
      config = { ...config, ...configOverride };
    }

    this.addMessage(0, message, null, config, indefinate);
    return null;
  }

  openConfirmationDialog(title: string, message: string, yesLabel?: string, optionalLabel?: string, noLabel?: string): Observable<any> {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "550px",
      data: {
        title,
        text: message,
        yesLabel: yesLabel,
        noLabel: noLabel,
        optionalLabel: optionalLabel
      },
    });

    return dialogRef.afterClosed();
  }

  openNotificationDialog(title: string, message: string, indefinate: boolean = false): Observable<any> {
    const dialogRef = this.matDialog.open(NotificationDialogComponent, {
      width: "550px",
      data: {
        title,
        text: message,
      },
    });
    
    return dialogRef.afterClosed();
  }

  addMessage(priority: number, message: string, action?: string, config?: MatSnackBarConfig, indefinate: boolean = false) {
    if (config.duration == null || indefinate) {
      this.indefinateItem = new MessageQueueItem(priority, message, null, config, true)
    } else {
      this.queue.push(new MessageQueueItem(priority, message, null, config));
      this.queue.sort((a, b) => a.priority - b.priority);
    }

    if (priority < this.currentMessage?.priority) {
      //overwrites the current message
      this.popMessage();
    }

    if (this.currentMessage == null) {
      //first item
      this.popMessage();
    }
  }

  popMessage() {
    this.currentMessage = this.queue.shift();

    this.snackbarSubscription?.unsubscribe();
    if (!this.currentMessage) {
      //show indefinate item
      if (!this.indefinateItem || !this.indefinateItem.message) {
        return;
      }

      this.snackbarRef = this.snackBar.open(this.indefinateItem.message, this.indefinateItem.action, this.indefinateItem.config);
      return;
    }

    this.snackbarRef = this.snackBar.open(this.currentMessage.message, this.currentMessage.action, this.currentMessage.config);
    this.snackbarSubscription = this.snackbarRef.afterDismissed().subscribe(() => {
      //after dismiss
      this.popMessage();
    });
  }

  dismissQueue() {
    this.queue = [];
    this.indefinateItem = null;
    this.currentMessage = null;
    this.snackBar.dismiss();
  }
}
