<div class="dialog">
  <button class="dialog-close" type="button" mat-mini-fab color="primary" (click)="close(null)">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="mat-headline">
          <span>Groep toevoegen</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button type="submit" [disabled]="saving" mat-raised-button color="primary">Opslaan</button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field fxFlex="100%">
          <mat-label>Groep *</mat-label>
          <mat-select formControlName="AdId" (selectionChange)="onGroupChanged()">
            <mat-option *ngFor="let option of groups" [value]="option.AdId">{{ option.Name.replaceAll("_", " ") }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
