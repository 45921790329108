import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth/auth.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-auth-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  environment = environment;

  loginForm: FormGroup = this.fb.group({
    Email: [{ value: null, disabled: true }, [Validators.required]],
    Password: [{ value: null, disabled: true }, [Validators.required]],
  });

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.authService.logout(true);
        return;
      }

      this.router.navigateByUrl("/");
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  login(): void {
    this.authService.loginWithSSO();
  }

  logout(): void {
    this.authService.logout(false);
  }
}
