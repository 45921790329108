import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoketService } from '../shared/services/loket.service';
import { SettingsService } from '../shared/services/settings.service';
import { Router } from '@angular/router';
import { UIState } from '../shared/state/ui.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download-center',
  templateUrl: './download-center.component.html',
  styleUrls: ['./download-center.component.scss']
})
export class DownloadCenterComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(public loketService: LoketService, private settingService: SettingsService, private route: Router, private uiState: UIState) { }

  ngOnInit(): void {
    if (!Boolean(JSON.parse(this.settingService.getSettingByKey("module-eid")?.Value || "0"))) {
      this.route.navigateByUrl('/loket');
    }

    this.uiState.breadcrumbs$.next([{
      name: "Download Center"
    }]);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openChromeExtension(): void {
    const url = "https://chrome.google.com/webstore/detail/eid-x/pmjcgkekkhobcpbneajeaiiakmpamkpf";
    window.open(url, "_blank");
  }
  downloadEid(platform: string, arch: string, outputFile: string): void {
    this.subscriptions.push(this.loketService.downloadEid(platform, arch).subscribe((file) => {
      const a = document.createElement("a");
      a.setAttribute("type", "hidden");  
      a.href = window.URL.createObjectURL(file);  
      // a.download = `eID-X Installer.pkg`;
      a.download = outputFile;
      a.click(); 
      a.remove();
    })); 
  }

}
