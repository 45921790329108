import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Response } from '../models/response.model';
import { AppState } from '../state/app.state';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  constructor(
    private http: HttpClient,
    private appState: AppState
  ) {}

  getDocumentation(): Observable<string> {
    return this.http.get(`${this.appState.baseApiUrl}/documentation`, httpOptions)
      .pipe(map((response: Response) => {
        if (!response.Success) { 
          return; 
        }
        
        return response.Data;
    }));
  }

  getImage(filename: string): Observable<Blob> {
    return this.http.get(`${this.appState.baseApiUrl}/documentation/image/${filename}`, { responseType: "blob" });
  }
}