// tslint:disable: no-console
// tslint:disable: typedef

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class Logger {
  constructor() {
    //  console.log(" !!!!initialize logger");
  }

  debug = localStorage.getItem("debug") || !environment.production || environment.test;

  log(...args) {
    if (this.debug) {
      console.log.apply(console, args);
    }
  }

  info(...args) {
    if (this.debug) {
      console.info.apply(console, args);
    }
  }

  // why are there here ...args and console apply ????
  errorArgs(...args) {
    if (this.debug) {
      console.error.apply(console, args);
    }
  }

  error(obj: any) {
    console.error(obj);
  }

  count(arg) {
    if (this.debug) {
      console.count(arg);
    }
  }

  group(arg) {
    if (this.debug) {
      console.group(arg);
    }
  }

  groupEnd() {
    if (this.debug) {
      console.groupEnd();
    }
  }

  time(arg) {
    if (this.debug) {
      console.time(arg);
    }
  }

  timeEnd(arg) {
    if (this.debug) {
      console.timeEnd(arg);
    }
  }

  ais_logPageView(name?: string, url?: string) {
    // if (environment.appInsightsInstrumentationKey.trim() != "") {
    //   this.appInsights.logPageView(name, url);
    // }
  }

  ais_event(name: string, properties?: { [key: string]: any }) {
    // if (environment.appInsightsInstrumentationKey.trim() != "") {
    //   this.appInsights.logEvent(name, properties);
    // }
  }
}
