import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs";
import { Logger } from "../helpers/logger";
import { StorageService } from "../services/storage.service";
import { AppState } from "../state/app.state";

@Injectable({
  providedIn: "root",
})
export class IsMulticommunityGuard implements CanActivateChild {
  // nxcode
  // ismulticomunity
  constructor(
    private appState: AppState,
    private storageService: StorageService,
    private router: Router,
    private logger: Logger,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.logger.info("IsMulticommunityGuard - canActivateChild");

    // if it is a igs go only to reports
    if (!this.appState.activeCommunity$.value.IsCommunity) {
      this.router.navigateByUrl("rapportering");
      return false;
    }
    return true;
  }
}
